import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGooglePlus, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import axios from "axios";


function Footer() {
    const hasToken = localStorage.getItem('token');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    useEffect(() => {
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("https://api.nagaratharcoc.com/api/v1/common/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
return(
<div>
<div class="intro-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-12">
                <div class="intro-text">
                    <h3>Do You Have Questions ?</h3>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                < Link to ="/Contact"  class="btn btn-md">  Get in Touch with us </Link> 
            </div>
        </div>
    </div>
</div>
<footer class="footer">
    <div class="container footer-inner">
        <div class="row">
            <div class="col-xl-4 col-lg-3 col-md-6 col-sm-6">
                <div class="footer-item clearfix">
                    {/* <img src="img/logos/logo.png" alt="logo" class="f-logo"/> */}
                    <ul class="contact-info">
                        <li>
                            <i class="flaticon-pin"></i># S-2, No. 171/113,
                            St.Mary's Road,<br/>
                            Abhirami puram ,
                            Chennai - 600 018.
                        </li>
                        <li>
                            <i class="flaticon-mail"></i><a href="mailto:sales@hotelempire.com" style={{textDecoration:'none'}}>admin@nagaratharcoc.com </a>
                        </li>
                        <li>
                            <i class="flaticon-phone"></i><a href="tel:+55-417-634-7071" style={{textDecoration:'none'}}>+91 63806 60796</a>
                        </li>
                         
                    </ul>
                    <div class="clearfix"></div>
                    
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div class="footer-item">
                    <h4>
                        Useful Links
                    </h4>
                    <ul class="links">
                    <li className="nav-item"><Link to="/Home" className="nav-link">Home</Link></li>
                    <li className="nav-item"><Link to="/About" className="nav-link">About us</Link></li>
                        
                        
                    <li><Link to ="/Member" className="dropdown-item">Founding EC Team</Link></li>
                    <li><Link to ="/Current" className="dropdown-item">Current EC Team</Link></li>
                    {/* <li><Link to ="/Acemember" className="dropdown-item" >ACE Member</Link></li>
                    <li><Link to ="/Yesmember" className="dropdown-item">YES Member</Link></li> */}
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="footer-item">
                    <h4>
                         
                    </h4>
                    <ul class="links">
                        <li>
                            {/* <Link to="/Byelaw">By law</Link> */}
                        </li>
                        <li>
                            <Link to="/Login" style={{textDecoration:'none'}}>Login</Link>
                        </li>
              <li>
                <Link to="/Register" style={{textDecoration:'none'}}>New Member</Link>
              </li>
                        {/* <li>
                            <a href="#" style={{textDecoration:'none'}}>Offline Registration form</a>
                        </li> */}
                         
                    </ul>
                </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
    <div className="footer-item clearfix">
        <h4>Connect with us</h4>
        <div className="social-list-2">
            <ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex' }}>
            {data.map((socialMedia, index) => (
    <li key={socialMedia.commoninfoid} style={{ marginRight: '10px' }}>
        <Link to={socialMedia.description} className="" target="_blank" rel="noopener noreferrer">
            <img
                src={socialMedia.iconimage}
                alt={socialMedia.title}
                style={{
                    width: '30px', // Adjust the width as needed
                    height: '30px', // Adjust the height as needed
                    borderRadius: '50%',
                }}
            />
        </Link>
    </li>
))}
            </ul>
        </div>
    </div>
</div>

            <div class="col-xl-12 col-lg-12">
                <p class="copy sub-footer">© 2023 <a href="#" style={{textDecoration:'none'}}>Copyright @ 2019 NCC All Rights Reserved.</a><a href="riotnfomedia.com" style={{textDecoration:'none'}}>Made with love Riotinfomedia</a></p>
            </div>
        </div>
    </div>
</footer>
</div>
    )
}
export default Footer;