import React, { useState} from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker , faPhone, faEnvelope, faGlobe  } from '@fortawesome/free-solid-svg-icons';
import Header from "../header";
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
function Contact() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
    const [fname, setFname] =useState('');
    const [email, setEmail] =useState('');
    const [subject, setSubject] =useState('');
    const [message, setMessage] =useState('');
    const [number, setNumber] =useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		try {
		  // TODO: Replace with actual API call
		  const response = await fetch("https://api.nagaratharcoc.com/api/v1/commondetails/add", {
			method: "PUT",
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name:fname,
				email,
				subject,
				number,
                message,
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			// navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			console.log("POST request successful");
            setData(response.data);
        setSuccessMessage("Message sent successfully"); // Set success message
        // Optionally, you can clear the form fields here:
        setFname('');
        setEmail('');
        setSubject('');
        setNumber('');
        setMessage('');
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); 
			  }
			window.location.reload(); // Refresh the page
			if (data.token) {
			  localStorage.setItem('token', token);
			}
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		}
	  };
return(
        <div>
            <Header/>
        
        <div class="contact-1 content-area-5">
    <div class="container">
        {/* <!-- Main title --> */}
        <div class="main-title text-center">
            <h1>Contact Us</h1>
            <p> </p>
        </div>
        {/* <!-- Contact info --> */}
        <div class="contact-info">
            <div class="row">
                <div class="col-md-3 col-sm-6 mrg-btn-50">
                    <FontAwesomeIcon icon={faMapMarker}/>
                    <p>Office Address</p>
                    <strong># S-2, No. 171/113,
                        St.Mary's Road,
                        Abhirami puram ,
                        Chennai - 600 018.</strong>
                </div>
                <div class="col-md-3 col-sm-6 mrg-btn-50">
                    <FontAwesomeIcon icon={faPhone}/>
                    <p>Phone Number</p>
                    <strong>+91 63806 60796</strong>
                </div>
                <div class="col-md-3 col-sm-6 mrg-btn-50">
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <p>Email Address</p>
                    <strong>admin@nagaratharcoc.com</strong>
                </div>
                <div class="col-md-3 col-sm-6 mrg-btn-50">
                    <FontAwesomeIcon icon={faGlobe}/>
                    <p>Web</p>
                    <strong>nagaratharcoc.com</strong>
                </div>
            </div>
        </div>
        <form onSubmit={onFinish}>
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <div class="form-group name">
                                <input type="text" name="name" class="form-control" placeholder="Name" value={fname} onChange={(e) => setFname(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group email">
                                <input type="text" name="email" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group subject">
                                <input type="text" name="subject" class="form-control" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group number">
                                <input type="text" name="phone" class="form-control" placeholder="Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group message">
                                
                            </div><textarea class="form-control" name="message" placeholder="Write message" style={{ height: "260px" }} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                        <div class="col-md-12">
                            <div class="send-btn text-center">
                                <button type="submit" class=" btn-md button-theme">Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="opening-hours">
                        <h3>Opening Hours</h3>
                        <ul class="list-style-none">
                            <li><strong>Sunday</strong> <span class="text-red"> closed</span></li>
                            <li><strong>Monday</strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Tuesday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Wednesday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Thursday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Friday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Saturday </strong> <span> 10 AM - 8 PM</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
        {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
    </div>
    <div class="section">
    <div class="map">
        <div id="map" class="contact-map"></div>
    </div>
</div>
</div>
<Footer/>
</div>
    )
}
export default Contact;