import React from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";


function Registarion() {
    return(
        <div class="container">
<div class="dashboard-list">
    <h3 class="main-title">Persolan Details</h3>
    <div class="dashboard-message contact-2 bdr clearfix">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                {/* <!-- Edit profile photo --> */}
                <div class="edit-profile-photo">
                    <img src="img/avatar/founding_member.jpg" alt="profile-photo" class="img-fluid"/>
                    <div class="change-photo-btn">
                        <div class="photoUpload">
                            <span><i class="fa fa-upload"></i> Upload Photo</span>
                            <input type="file" class="upload"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <form action="#" method="GET" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group name">
                                <label>Your Name</label>
                                <input type="text" name="name" class="form-control" placeholder="Enter"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Gender</label>
                                <label class="custom-select-select"/>
                                    <select  name='options'>
                                      <option value="0">Gender</option>
                                    <option>select</option>
                                    <option>Male</option>
                                    <option>Fe-male</option>
                                </select>
                            </div>
                            
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Marital  Status </label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Marital  Status</option>
                                    <option>select</option>
                                    <option>Single</option>
                                    <option>Married</option>
                                </select>
                            </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group subject">
                                <label>DOB</label>
                                <input type="date" name="COMPANY NAME" class="form-control" placeholder="Enter"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Address 1</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Country</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Start</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>City</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Area</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                        </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Pincode</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Native</option>
                                      <option> Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option>Amaravathipudur</option>
                                    <option>Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option>Athikkadu Thekkur</option>
                                    <option>Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option>Avanipatti</option>
                                    <option>Chockalingam Pudur</option>
                                    <option>Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option> Amaravathipudur</option>
                                    <option> Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option> Athikkadu Thekkur</option>
                                    <option> Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option> Avanipatti</option>
                                    <option> Chockalingam Pudur</option>
                                    <option> Chocknathapuram</option>
                                    <option>Devakottai</option>
                                    <option> Kaliarmangalam</option>
                                    <option> Kallal</option>
                                    <option> Kandavarayanpatti</option>
                                    <option> Kandanur</option>
                                    <option>Karaikudi</option>
                                    <option>Karunkulam</option>
                                    <option> Kilapungudi</option>
                                    <option> Kilasivalpatti</option>
                                    <option> P.Alagapuri</option>
                                    <option> Kollangudi Alagapuri</option>
                                    <option> Konapet</option>
                                    <option> Koppanapatti</option>
                                    <option> Kottamangalam</option>
                                    <option>Kottaiyur</option>
                                    <option>Kulipirai</option>
                                    <option>Kuruvikondanpatti</option>
                                    <option>Lakshmipuram</option>
                                    <option>Kothamangalam Lakshipuram</option>
                                    <option>Madaguppatti</option>
                                    <option>Chockalingapuram</option>
                                    <option>Mahibalanpatti</option>
                                    <option>Managiri</option>
                                    <option>Melasivapuri</option>
                                    <option>Mithalaipatti</option>
                                    <option>Nachundupatti</option>
                                    <option>Nachiapuram</option>
                                    <option>Natarajapuram</option>
                                    <option>Nemathanpatti</option>
                                    <option> Nerkupai</option>
                                    <option> Okkur</option>
                                    <option>Oyakondan Siruvayal</option>
                                    <option>Panageri</option>
                                    <option>Palavangudi</option>
                                    <option> Pallathur</option>
                                    <option> Panagudi</option>
                                    <option> Panayapatti</option>
                                    <option> Pariamaruthapatti</option>
                                    <option> Pattamangalam</option>
                                    <option> Pillaiyarpatti</option>
                                    <option>Ponnamaravathy</option>
                                    <option>Pulankurichi</option>
                                    <option>Pudupatti (Ponnamaravathy)</option>
                                    <option>Puduvayal</option>
                                    <option> Ramachandrapuram</option>
                                    <option> Rangiam</option>
                                    <option> Rajavaram</option>
                                    <option> Sakkandi</option>
                                    <option> Sembanur</option>
                                    <option>Sevvur</option>
                                    <option>Shanmuganathapuram (Aravayal)</option>
                                    <option> Siravayal</option>
                                    <option> Sirukudalpatti</option>
                                    <option> Solapuram</option>
                                    <option> Thanichavurani</option>
                                    <option> Thenipatti</option>
                                    <option>Ulagampatti</option>
                                    <option>Valayapatti</option>
                                    <option>Vegupatti</option>
                                    <option>Veendhampatti</option>
                                    <option> Vetriyur</option>
                                    <option>Virachalai</option>
                                    <option> Viramathi (Kilasivalpatti)</option>
                                    <option>V.Lakshipuram (Virachalai)</option>
                                    </select>
                                  </label>
                               
                                 
                                 
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Kovil</option>
                                    <option>Vairavan Kovil</option>
                                    <option>Ilayathakudi</option>
                                    <option>Mathur</option>
                                    <option>Iraniyur</option>
                                    <option>Pillaiyarpatti</option>
                                    <option>Soorakudi</option>
                                    <option>Iluppaikkudi</option>
                                    <option>Nemam</option>
                                    <option>Velankudi</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Perivu</option>
                                    <option>Periya Vakuppu</option>
                                    <option>Teyanar, Tevanayakkar</option>
                                    <option>Pillaiyar Vakuppu</option>
                                    <option>Kalanivasal</option>
                                    <option>Maruttendrapuram</option>
                                    <option>Okkur</option>
                                    <option>Arumburkolar (alias Pattanasamy)</option>
                                    <option>Perumarudur</option>
                                    <option>Kinkanikkur</option>
                                    <option>Kalanivasal</option>
                                    <option>Perasandur</option>
                                    <option>Sirusettur</option>
                                    <option>Uraiyur</option>
                                    <option>Arumbakkur</option>
                                    <option>Manalur</option>
                                    <option>Mannur</option>
                                    <option>Kannur</option>
                                    <option>Karuppur</option>
                                    <option>Kulattur</option>
                                
                                </select>
                            </label>
                            </div>
                        </div>
                        <h3 class="main-title">Spouse  Details</h3>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <label class="custom-select-select"/>
                                    <select  name='options'>
                                      <option value="0">Native</option>
                                    <option> Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option>Amaravathipudur</option>
                                    <option>Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option>Athikkadu Thekkur</option>
                                    <option>Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option>Avanipatti</option>
                                    <option>Chockalingam Pudur</option>
                                    <option>Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option> Amaravathipudur</option>
                                    <option> Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option> Athikkadu Thekkur</option>
                                    <option> Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option> Avanipatti</option>
                                    <option> Chockalingam Pudur</option>
                                    <option> Chocknathapuram</option>
                                    <option>Devakottai</option>
                                    <option> Kaliarmangalam</option>
                                    <option> Kallal</option>
                                    <option> Kandavarayanpatti</option>
                                    <option> Kandanur</option>
                                    <option>Karaikudi</option>
                                    <option>Karunkulam</option>
                                    <option> Kilapungudi</option>
                                    <option> Kilasivalpatti</option>
                                    <option> P.Alagapuri</option>
                                    <option> Kollangudi Alagapuri</option>
                                    <option> Konapet</option>
                                    <option> Koppanapatti</option>
                                    <option> Kottamangalam</option>
                                    <option>Kottaiyur</option>
                                    <option>Kulipirai</option>
                                    <option>Kuruvikondanpatti</option>
                                    <option>Lakshmipuram</option>
                                    <option>Kothamangalam Lakshipuram</option>
                                    <option>Madaguppatti</option>
                                    <option>Chockalingapuram</option>
                                    <option>Mahibalanpatti</option>
                                    <option>Managiri</option>
                                    <option>Melasivapuri</option>
                                    <option>Mithalaipatti</option>
                                    <option>Nachundupatti</option>
                                    <option>Nachiapuram</option>
                                    <option>Natarajapuram</option>
                                    <option>Nemathanpatti</option>
                                    <option> Nerkupai</option>
                                    <option> Okkur</option>
                                    <option>Oyakondan Siruvayal</option>
                                    <option>Panageri</option>
                                    <option>Palavangudi</option>
                                    <option> Pallathur</option>
                                    <option> Panagudi</option>
                                    <option> Panayapatti</option>
                                    <option> Pariamaruthapatti</option>
                                    <option> Pattamangalam</option>
                                    <option> Pillaiyarpatti</option>
                                    <option>Ponnamaravathy</option>
                                    <option>Pulankurichi</option>
                                    <option>Pudupatti (Ponnamaravathy)</option>
                                    <option>Puduvayal</option>
                                    <option> Ramachandrapuram</option>
                                    <option> Rangiam</option>
                                    <option> Rajavaram</option>
                                    <option> Sakkandi</option>
                                    <option> Sembanur</option>
                                    <option>Sevvur</option>
                                    <option>Shanmuganathapuram (Aravayal)</option>
                                    <option> Siravayal</option>
                                    <option> Sirukudalpatti</option>
                                    <option> Solapuram</option>
                                    <option> Thanichavurani</option>
                                    <option> Thenipatti</option>
                                    <option>Ulagampatti</option>
                                    <option>Valayapatti</option>
                                    <option>Vegupatti</option>
                                    <option>Veendhampatti</option>
                                    <option> Vetriyur</option>
                                    <option>Virachalai</option>
                                    <option> Viramathi (Kilasivalpatti)</option>
                                    <option>V.Lakshipuram (Virachalai)</option>
                                
                                </select>
                                 
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Kovil</option>
                                    <option>Vairavan Kovil</option>
                                    <option>Ilayathakudi</option>
                                    <option>Mathur</option>
                                    <option>Iraniyur</option>
                                    <option>Pillaiyarpatti</option>
                                    <option>Soorakudi</option>
                                    <option>Iluppaikkudi</option>
                                    <option>Nemam</option>
                                    <option>Velankudi</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Perivu</option>
                                    <option>Periya Vakuppu</option>
                                    <option>Teyanar, Tevanayakkar</option>
                                    <option>Pillaiyar Vakuppu</option>
                                    <option>Kalanivasal</option>
                                    <option>Maruttendrapuram</option>
                                    <option>Okkur</option>
                                    <option>Arumburkolar (alias Pattanasamy)</option>
                                    <option>Perumarudur</option>
                                    <option>Kinkanikkur</option>
                                    <option>Kalanivasal</option>
                                    <option>Perasandur</option>
                                    <option>Sirusettur</option>
                                    <option>Uraiyur</option>
                                    <option>Arumbakkur</option>
                                    <option>Manalur</option>
                                    <option>Mannur</option>
                                    <option>Kannur</option>
                                    <option>Karuppur</option>
                                    <option>Kulattur</option>
                                </select>
                            </label>
                            </div>
                        </div>
                        
                        <h3 class="main-title">Father  Details</h3>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Native</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Native</option>
                                    <option> Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option>Amaravathipudur</option>
                                    <option>Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option>Athikkadu Thekkur</option>
                                    <option>Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option>Avanipatti</option>
                                    <option>Chockalingam Pudur</option>
                                    <option>Alagapuri (Kottaiyur)</option>
                                    <option>Alavakottai</option>
                                    <option> Amaravathipudur</option>
                                    <option> Aranmanai Siruvayal</option>
                                    <option>Arimalam</option>
                                    <option>Ariyakudi</option>
                                    <option> Athikkadu Thekkur</option>
                                    <option> Attangudi</option>
                                    <option> Attangudi Muthupattinam</option>
                                    <option> Avanipatti</option>
                                    <option> Chockalingam Pudur</option>
                                    <option> Chocknathapuram</option>
                                    <option>Devakottai</option>
                                    <option> Kaliarmangalam</option>
                                    <option> Kallal</option>
                                    <option> Kandavarayanpatti</option>
                                    <option> Kandanur</option>
                                    <option>Karaikudi</option>
                                    <option>Karunkulam</option>
                                    <option> Kilapungudi</option>
                                    <option> Kilasivalpatti</option>
                                    <option> P.Alagapuri</option>
                                    <option> Kollangudi Alagapuri</option>
                                    <option> Konapet</option>
                                    <option> Koppanapatti</option>
                                    <option> Kottamangalam</option>
                                    <option>Kottaiyur</option>
                                    <option>Kulipirai</option>
                                    <option>Kuruvikondanpatti</option>
                                    <option>Lakshmipuram</option>
                                    <option>Kothamangalam Lakshipuram</option>
                                    <option>Madaguppatti</option>
                                    <option>Chockalingapuram</option>
                                    <option>Mahibalanpatti</option>
                                    <option>Managiri</option>
                                    <option>Melasivapuri</option>
                                    <option>Mithalaipatti</option>
                                    <option>Nachundupatti</option>
                                    <option>Nachiapuram</option>
                                    <option>Natarajapuram</option>
                                    <option>Nemathanpatti</option>
                                    <option> Nerkupai</option>
                                    <option> Okkur</option>
                                    <option>Oyakondan Siruvayal</option>
                                    <option>Panageri</option>
                                    <option>Palavangudi</option>
                                    <option> Pallathur</option>
                                    <option> Panagudi</option>
                                    <option> Panayapatti</option>
                                    <option> Pariamaruthapatti</option>
                                    <option> Pattamangalam</option>
                                    <option> Pillaiyarpatti</option>
                                    <option>Ponnamaravathy</option>
                                    <option>Pulankurichi</option>
                                    <option>Pudupatti (Ponnamaravathy)</option>
                                    <option>Puduvayal</option>
                                    <option> Ramachandrapuram</option>
                                    <option> Rangiam</option>
                                    <option> Rajavaram</option>
                                    <option> Sakkandi</option>
                                    <option> Sembanur</option>
                                    <option>Sevvur</option>
                                    <option>Shanmuganathapuram (Aravayal)</option>
                                    <option> Siravayal</option>
                                    <option> Sirukudalpatti</option>
                                    <option> Solapuram</option>
                                    <option> Thanichavurani</option>
                                    <option> Thenipatti</option>
                                    <option>Ulagampatti</option>
                                    <option>Valayapatti</option>
                                    <option>Vegupatti</option>
                                    <option>Veendhampatti</option>
                                    <option> Vetriyur</option>
                                    <option>Virachalai</option>
                                    <option> Viramathi (Kilasivalpatti)</option>
                                    <option>V.Lakshipuram (Virachalai)</option>
                               
                                </select>
                            </label>
                                 
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Kovil</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Kovil</option>
                                    <option>Vairavan Kovil</option>
                                    <option>Ilayathakudi</option>
                                    <option>Mathur</option>
                                    <option>Iraniyur</option>
                                    <option>Pillaiyarpatti</option>
                                    <option>Soorakudi</option>
                                    <option>Iluppaikkudi</option>
                                    <option>Nemam</option>
                                    <option>Velankudi</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Perivu</label>
                                <label class="custom-select-select">
                                    <select  name='options'>
                                      <option value="0">Perivu</option>
                                    <option>Periya Vakuppu</option>
                                    <option>Teyanar, Tevanayakkar</option>
                                    <option>Pillaiyar Vakuppu</option>
                                    <option>Kalanivasal</option>
                                    <option>Maruttendrapuram</option>
                                    <option>Okkur</option>
                                    <option>Arumburkolar (alias Pattanasamy)</option>
                                    <option>Perumarudur</option>
                                    <option>Kinkanikkur</option>
                                    <option>Kalanivasal</option>
                                    <option>Perasandur</option>
                                    <option>Sirusettur</option>
                                    <option>Uraiyur</option>
                                    <option>Arumbakkur</option>
                                    <option>Manalur</option>
                                    <option>Mannur</option>
                                    <option>Kannur</option>
                                    <option>Karuppur</option>
                                    <option>Kulattur</option>
                                </select>
                            </label>
                            </div>
                        </div>
                         <h3 class="main-title">Business  Details</h3>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Company Name</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Category</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                         <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Landline</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Mobile</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Email</label>
                                <input type="email" name="email" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Website Address</label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Address 1</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Country</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Start</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>City</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Area</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group number">
                                <label>Pincode</label>
                                <input type="text" name="text" class="form-control" placeholder="Place"/>
                            </div>
                        </div>
                        <h3 class="main-title">Refered by</h3>
                        <div class="col-lg-6 col-md-6 d-flex justify-content-center">
                            <div class="form-group number">
                                <label> </label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6 d-flex justify-content-center">
                            <div class="form-group number">
                                <label> </label>
                                <input type="text" name="text" class="form-control" placeholder="enter"/>
                         </div>
                        </div>
                        <div class="col-md-12">
                            <div class="send-btn text-center">
                                <button type="submit" class="btn btn-md button-theme">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
       
           
            
       
    </div>
</div>
</div>
    )
}
export default Registarion;             