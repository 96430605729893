import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import Header from "../header";
import Footer from "../footer";
import { Select, Modal,Button,notification } from "antd";
import noImage from "../img/noimage.jpg";

const { Option } = Select;

const rowStyle = {
  height: '15.0pt'
};

function AssociateMemberList() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const fetchDataforgetbyType = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/client/getbytypes",
        {
          membertype: "Associate Member",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const clientIds = response.data.map((item) => item.clientid);
      localStorage.setItem('clientIdsofAssociateMember',JSON.stringify(clientIds));
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCompanyCategories = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/companycategory/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setCompanyCategories(response.data);
    } catch (error) {
      console.error("Error fetching company categories:", error);
    }
  };
  useEffect(() => {
    fetchDataforgetbyType();
    fetchCompanyCategories();
    return () => {
      localStorage.removeItem("selectedCategory");
    };
  }, []);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.nagaratharcoc.com/api/v1/login/getclientdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    const filteredResults = data.filter((item) =>
      (selectedCategory ? item.companycategory === selectedCategory : true) &&
      ((item?.idno ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item?.firstname ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item?.companyname ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item?.companycategory ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item?.mobile ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item?.address1 ?? '').toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredData(filteredResults);
  }, [searchQuery, data, selectedCategory]);
  
  const handleRowClick = async (item) => {
    try {
      const clientid = item.clientid;
      localStorage.setItem('clickedClientId', clientid);
      if (!isAuthenticated) { 
        notification.warning({
        message: 'Please login to view the member details',
      });
        navigate('/Login');
      } else {
        navigate(`/UserProfile`);
      }
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };
  
  return (
    <div>
      <Header />

        <div className="container">
        <div class="main-title">
            <h1 style={{marginTop:'30px'}}>Associate Member</h1>
        {/* <div class="main-title text-left">
            <p> Any individual whom the Executive Committee admits as a member pays the
            Admission fee and subscription fee for a year and becomes an Associate 
            Chamber Member.</p>
<p><b>Benefits of becoming an Associate Chamber Member:</b></p>
<p>An Associate Chapter Member contests to be a part of the Executive Committee 
and be part of the Executive Committee and can hold offices of Secretary.</p>
        </div> */}
        </div>
         {loading ? (
            <p style={{textAlign:'center'}}>Loading...</p>
          )  : ( 
            <div className="row">
               <div className="" style={{marginLeft:"65%"}}>
                <Select
            style={{ width: 200, marginBottom: 16 }}
            placeholder="Select Company Category"
            onChange={(value) => setSelectedCategory(value)}
          >
          <option onClick={fetchCompanyCategories}>Show All</option>
            {companyCategories.map((category) => (
              <Option key={category.companycategoryid} value={category.companycategory}>
                {category.companycategory}
              </Option>
            ))}
          </Select>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{marginLeft:"30px"}}
            />
          </div>  
          {filteredData.length === 0 ? (
    <p>No members are in the list.</p>
) : (
              <table
                id="example"
                className="table table-striped table-bordered nowrap"
                style={{ width: '100%', margin:'20px 0 70px 0' }}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Category</th>
                    <th>Native</th>
                    <th>Place</th>
                    <th>Profile Pic</th>
                      <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.id} height={20} style={{ height: "15.0px" }} onClick={() => handleRowClick(item)}>
                      <td>{item.firstname}</td>
                      <td>{item.companyname}</td>
                      <td>{item.companycategory}</td>
                      <td>{item.nativee}</td>
                      <td>{item.city}</td>
                      <td><img src={item.profilepic != null ? item.profilepic : noImage} width="100px" height="100px" alt="Profile" /></td>
  <td> <Link to="" onClick={() => handleRowClick(item)}>View</Link>
</td>

                    </tr>
                  ))}
                </tbody>

                <tfoot>
                  <tr>
                    <th>Member ID</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Category</th>
                    <th>Contact</th>
                    <th>Address</th>
                      <th>Action</th>
                  </tr>
                </tfoot>
              </table>
)}
            </div>
           )} 
        </div>
     
      {showLoginModal && (
  <Modal
    title="Login Required"
    visible={showLoginModal}
    footer={null}
    onCancel={() => setShowLoginModal(false)}
  >
    <div className="row d-flex flex-column align-items-center justify-content-center">
      <p className="text-center">Please login to view the member details</p>
      <Link to="/Login" className="btn-md button-theme" style={{ width: "20%", textAlign: "center" }}>
        Login
      </Link>
    </div>
  </Modal>
)}

      <Footer />
    </div>
  );
}

export default AssociateMemberList;
