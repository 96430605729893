import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import axios from "axios";
import Header from "../header";
import Footer from "../footer";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import './style.css';
import DOMPurify from 'dompurify';

function Eventdetails() {
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showFullContent, setShowFullContent] = useState({});
  const [showAllEvents, setShowAllEvents] = useState(false);
  const navigate = useNavigate();
  const { eventid } = useParams();
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [shareUrl, setShareUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({
    eventimage: "",
    title: "",
    description: "",
  });
  const fetchEventData = async () => {
    try {

      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/event/getbyid",
        { eventid: eventid },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status !== 200) {
        console.error("API error:", response);
        // Handle error, set an error state, etc.
        return;
      }

      setEventData(response.data);
      // localStorage.removeItem("clickedEventId");
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error, set an error state, etc.
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);
  useEffect(() => {

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/event/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate("/login");
        return; // Exit early to prevent further logic execution
      }
      const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const toggleShowContent = (eventId) => {
    setShowFullContent((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId],
    }));
  };
  useEffect(() => {
    fetchDataforSocialMedia();
  }, []); // Empty dependency array ensures the effect runs only once
  const fetchDataforSocialMedia = async () => {
    try {
      const response = await axios.put("https://api.nagaratharcoc.com/api/v1/common/get", {}, {

        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/login');
        return; // Exit early to prevent further logic execution
      }
      setSocialMediaData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      setShareUrl(window.location.href);
      setShowModal(true);
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl)
      .then(() => alert('Link copied to clipboard!'))
      .catch((err) => console.error('Error copying to clipboard:', err));
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const geteventDetails = (event) => {
    window.location.href = `/Eventdetails/${event.eventid}`;
  };

  return (
    <div>
      <Header />
      <div class="blog-body content-area-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              {/* <!-- Blog 1 start --> */}
              <div class="blog-1 blog-big">
                <div class="blog-photo">
                  <img src={eventData.eventimage} alt="blog-img" class="img-fluid" />
                </div>
                <div class="detail">
                  <h3>
                    {eventData.title}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: eventData.description }}></p>

                  <br />
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      {eventData.files != null && eventData.files.length > 0 && (
                        <div class="blog-tags">
                          <Link
                            to={`/eventgallery/${eventData.eventid}`}
                            onClick={() => {
                              localStorage.setItem("clickedEventIdforgallery", JSON.stringify(eventData.eventid));
                            }}
                          >
                            Gallery
                          </Link>
                        </div>
                      )}
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="blog-social-list">
                        {/* Add a button to share the current link */}
                        <button onClick={handleShareClick}>Share Link</button>
                        {showModal && (
                          <div className="share-modal">
                            <p>Share this page:</p>
                            <input type="text" value={shareUrl} readOnly />
                            <button onClick={copyToClipboard}>Copy Link</button>
                            <button onClick={closeModal}>Close</button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Heading 2 --> */}


            </div>
            <div class="col-lg-4 col-md-12">
              <div class="sidebar-right">
                {/* <!-- Search box --> */}

                {/* <!-- Recent properties start --> */}
                <div class="widget recent-properties">
                  <div class="row">
                    <div class="col-md-6">
                      <h3 class="sidebar-title">Recent Events</h3></div>
                    <div style={{ textAlign: 'end' }} class="col-md-6">
                      <Link to="/Events" onClick={() => setShowAllEvents(!showAllEvents)}>
                        {showAllEvents ? "Show less" : "View All"}
                      </Link>
                    </div>
                  </div>
                  {data
                    .filter((event) => true)
                    .slice(0, showAllEvents ? data.length : 5)
                    .map((event) => (
                      <Link onClick={() => geteventDetails(event)}

                        style={{ textDecoration: "none" }}>
                        <div class="media mb-4" key={event.eventid}>
                          <a class="pr-3" href={`properties-details.html/${event.eventid}`}>
                            <img class="media-object" src={event.eventimage} alt="small-properties" />
                          </a>
                          <div class="media-body align-self-center">
                            <h5>
                              {event.title}
                            </h5>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  showFullContent[event.eventid]
                                    ? event.description
                                    : `${event.description.substring(0, 60)}...`
                                ),
                              }}
                            ></span>
                            {event.description.length > 60 && (
                              <button
                                onClick={() => toggleShowContent(event.eventid)}
                                className="btn btn-link"
                                style={{ marginLeft: "5px" }}
                              >
                                {showFullContent[event.eventid] ? "Show Less" : "Read More"}
                              </button>
                            )}
                            <div class="listing-post-meta">
                              <FontAwesomeIcon icon={faCalendar} /> {new Date(event.date).toLocaleDateString()}
                            </div>
                          </div>

                        </div>
                      </Link>
                    ))}
                </div>
                {/* <!-- Posts By Category Start --> */}

              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}
export default Eventdetails;