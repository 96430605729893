import React, { useState, useEffect } from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import Header from "../header";
import Footer from "../footer";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import axios from "axios";

// Helper function to get month name from number
const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('default', { month: 'long' });
};

function Gallery() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/event/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate("/login");
        return; // Exit early to prevent further logic execution
      }
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  // Sort events by date in descending order, handling null dates as "Unknown"
  const sortedEvents = data.sort((a, b) => {
    if (!a.date) return 1;
    if (!b.date) return -1;
    return new Date(b.date) - new Date(a.date);
  });

  // Group events by period (month-year), using "Unknown" for null dates
  const groupedEvents = sortedEvents.reduce((acc, event) => {
    let period;
    if (!event.date) {
      period = "Date not Mentioned";
    } else {
      const date = new Date(event.date);
      period = `${getMonthName(date.getMonth() + 1)} ${date.getFullYear()}`;
    }

    if (!acc[period]) {
      acc[period] = [];
    }
    acc[period].push(event);

    return acc;
  }, {});

  // Filter out periods with no events having files greater than zero
  const filteredGroupedEvents = Object.entries(groupedEvents).filter(([period, events]) =>
    events.some(event => event.files.length > 0)
  );

  return (
    <div>
      <Header />
      <div className="contact-1 content-area-5">
        <div className="container">
          {/* Main title */}
          <div className="main-title text-center">
            <div className="gallery">
              {filteredGroupedEvents.map(([period, events], index) => (
                <div key={index}>
                  <h3 className="m-4">{period}</h3>
                  {events.map((event) => (
                    event.files.length > 0 && (
                      <div key={event.eventid}>
                        <a href={`/Eventdetails/${event.eventid}`} className="event-link">
                          <h2 className="text-start">{event.title}</h2>
                        </a>
                        <div className="row">
                          {event.files.slice(0, 10).map((file, fileIndex) => {
                            // Extract the file extension from the URL
                            const fileExtension = file.file.split('.').pop().toLowerCase();
                            // Define an array of video file extensions
                            const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'];
                            // Determine if the file is a video based on its extension
                            const isVideo = videoExtensions.includes(fileExtension);

                            return (
                              <div className="col-md-4 mt-2 mb-2" key={fileIndex}>
                                {isVideo ? (
                                  <video controls className="img-fluid">
                                    <source src={file.file} type={`video/${fileExtension}`} />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img className="img-fluid" src={file.file} alt={file.alt || 'Event image'} onClick={() => openModal(file)} />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal visible={modalVisible} onCancel={closeModal} footer={null} width={750}>
        {selectedImage && (
          <img src={selectedImage.file} alt={selectedImage.alt} style={{ maxWidth: '100%' }} />
        )}
      </Modal>
    </div>
  );
}

export default Gallery;
