import React, { useState, useEffect } from "react";
import "../App.css";
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import Img from "../img/about.jpg";
import Image from "../img/icons/portfolio.png";
import Image2 from "../img/icons/networking.png";
import Image3 from "../img/icons/support.png";
import Image4 from "../img/icons/growth.png";
import Image5 from "../img/icons/save.png";
import Footer from "../footer";
import Header from "../header";
import axios from "axios";

function About() {
  const [termsConditions, setTermsconditions] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchCommonDetails = async () => {
    try {
      const payload = {
        commondetailsfield: "aboutus",
      };
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/commondetails/getcommondetails",
        payload
      );

      if (response.status === 200) {
        // Successfully fetched common details
        const commonDetails = response.data;
        setTermsconditions(commonDetails.commondetails);
        setLoading(false); // Set loading to false once data is received
        // Do something with commonDetails, such as updating your component's state
        console.log("Common Details:", commonDetails);
      } else {
        console.error("Failed to fetch common details");
      }
    } catch (error) {
      console.error("Error fetching common details:", error);
    }
  };

  // useEffect to fetch common details when the component mounts
  useEffect(() => {
    fetchCommonDetails();
  }, []);

  return (
    <div>
      <Header />

      {loading ? (
        <div className="text-center">
          <p>Loading...</p>
          {/* You can use a loading spinner here if you have one */}
        </div>
      ) : (
        <>
          <div class="about-real-estate  content-area-5">
            <div class="container">
              <div class="row">
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                  <div class="about-slider-box simple-slider">
                    <img
                      class="d-block w-100"
                      src={Img}
                      alt="about"
                    />
                  </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                  <div class="about-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: termsConditions,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ... rest of your component */}
          <Footer />
        </>
      )}
    </div>
  );
}

export default About;
