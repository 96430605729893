import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import axios from "axios";


function Membertest() {
  const { referenceid } = useParams();
  const [referData, setReferData] = useState({});
  const fetchReferData = async () => {
    try {

      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/referal/getbyid",
        { referenceid: parseInt(referenceid) },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status !== 200) {
        console.error("API error:", response);
        // Handle error, set an error state, etc.
        return;
      }

      setReferData(response.data);
      // localStorage.removeItem("clickedEventId");
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error, set an error state, etc.
    } finally {
    }
  };

  useEffect(() => {
    fetchReferData();
  }, []);
  const handleAccept = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/referal/request",
        { referenceid: parseInt(referenceid), accepted: true },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        console.log("Accepted");
        // Perform additional actions if needed
      } else {
        console.error("API error:", response);
        // Handle error, set an error state, etc.
      }
    } catch (error) {
      console.error("Error making API call:", error);
      // Handle error, set an error state, etc.
    }
  };


  const handleDeny = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/referal/request",
        { referenceid: parseInt(referenceid), denied: false },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        console.log("Denied");
        // Perform additional actions if needed
      } else {
        console.error("API error:", response);
        // Handle error, set an error state, etc.
      }
    } catch (error) {
      console.error("Error making API call:", error);
      // Handle error, set an error state, etc.
    }
  };
return (
  <div className="elements-page content-area">
  <div className="container" style={{ border: "1px solid #ddd", marginTop: "10px", marginBottom: "10px" }}>
    <div className="row">
      <div className="box">
        <h1 className="d-flex justify-content-center  mb-4">Reference Request</h1>
        <div className="refer-info d-flex flex-column align-items-center">
          <h4 className="mb-4">Name: {referData.name}</h4>
          <h4 className="" style={{marginBottom:"5%"}}>Company: {referData.companyname}</h4>
        </div>
        <div className="row " style={{ marginTop: "10px", marginBottom: "10px" }}>
          <h5 className="col-md-4 col-sm-12 mt-1">Do you want to accept or deny?</h5>
          <div className="col-md-4 col-sm-12 mt-1">
            <button className="btn"   style={{ backgroundColor:"#2b3d58",color:"#fff"}} onClick={handleAccept}>
              Accept
            </button>
          </div>
          <div className="col-md-4 col-sm-12 mt-1 ">
            <button className="btn btn-danger" onClick={handleDeny}>
              Deny
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}   
export default Membertest;