import React, { useState, useEffect  } from "react";
import '../App.css';
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/default.css";
import "../css/slick.css";
import Image from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import noImage from "../img/noimage.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import axios from "axios";

function UserProfile() {
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        // Retrieve the clientid from local storage
        const clientId = localStorage.getItem("clickedClientId");
    
        if (clientId) {
          // Make the API call using the retrieved clientid
          const fetchUserDetails = async () => {
            try {
              const response = await axios.post(
                "https://api.nagaratharcoc.com/api/v1/client/getbyid",
                { clientid: parseInt(clientId )},
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Content-Type": "application/json",
                  },
                }
              );
    
              // Set the user data in state
              setUserData(response.data);
              localStorage.removeItem("clickedClientId");
            } catch (error) {
              console.error("Error fetching user details:", error);
            }
          };
    
          fetchUserDetails();
        }
      }, []);
return(
        <div>        
        <Header/>
    <div class="our-team content-area">
<div class="container">
    
    {/* <div class="main-title">
        <h1>YES Members</h1>
        <p></p>
    </div> */}
    
    <div class="row own">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="team-1">
                <div class="team-photo" style={{ backgroundColor: "#2b3d58" }}>
                    <a href="#">
                        <img  src={userData?.profilepic || noImage} alt="agent" class="img-fluid" style={{ margin: "15px" }}/>
                    </a>
    {userData && (
                    <div className="#" style={{ color: "#fff" }}>
                                            <h2>{userData.firstname}.{userData.lastname}</h2>
                    <h5>{userData.companyname}</h5>
                    <h5>{userData.companycategory}</h5>
                    </div>

                    
 )}
                    
                  
                </div>
            </div>
        </div>
       
      
    </div>
    
    {userData && (
    <div className="row">
    <div class="col-xl-6 col-lg-3 col-md-6 col-sm-6 align">
        <div className="#" style={{ color: "#000" }}>

<h2>Business Address</h2>
<h5>{userData.companyaddress1}</h5>
<h5>{userData.companycity} {userData.companystate} {userData.companycountry}</h5>
</div>

        </div>
       

        <div class="col-xl-6 col-lg-3 col-md-6 col-sm-6 align">
        <div className="#" style={{ color: "#000" }}>

<h2>Contact</h2>
<h5>Email: {userData.email}</h5>
<h5>Phone No: {userData.mobile}</h5>
</div>

        </div>
        </div>
    
    )}
</div>


</div>

<Footer/>
</div>

    )
}
export default UserProfile;