import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import image from "./../img/logo.png";
import { Link , useNavigate} from "react-router-dom";
import axios from "axios";
function Header() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    const response = await fetch("https://api.nagaratharcoc.com/api/v1/login/getclientdetails", {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error("Error fetching client details:", error);
                setIsAuthenticated(false);
            }
        };

        fetchClientDetails();
    }, []);
    useEffect(() => {
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("https://api.nagaratharcoc.com/api/v1/common/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        localStorage.clear();
      };
    
return (
        <div className="page_loader">
            <header className="top-header" id="top-header-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-9 col-sm-7">
                            <div className="list-inline">
                                <a href="tel:+91 63806 60796"><FontAwesomeIcon icon={faPhone} />+91 63806 60796</a>
                                <a href="tel:admin@nagaratharcoc.com"><FontAwesomeIcon icon={faEnvelope} />admin@nagaratharcoc.com</a>
                                {/* <Link to="/Register">Download Registration form</Link>
                                <Link to ="/Contact"><FontAwesomeIcon icon={faAddressBook} />Contact</Link> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3 col-sm-5">
                            
                            {data.map((socialMedia, index) => (
    
        <Link to={socialMedia.description} className="" target="_blank" rel="noopener noreferrer" style={{paddingLeft:'25px',float:'right'}}>
            <img
                src={socialMedia.iconimage}
                alt={socialMedia.title}
                style={{
                    width: '30px', // Adjust the width as needed
                    height: '30px', // Adjust the height as needed
                    borderRadius: '50%',
                }}
            />
        </Link>
    
))}


                           
                        </div>
                    </div>
                </div>
            </header>
            <header className="main-header fixed-header-2">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link to="/Home" className="navbar-brand company-logo">
                            <img src={image} alt="logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav header-ml">
                                <li className="nav-item"><Link to="/Home" className="nav-link">Home</Link></li>
                                <li className="nav-item"><Link to="/About" className="nav-link">About us</Link></li>
                                {/* <li className="nav-item"><Link to="/Byelaw" className="nav-link">Bye law</Link></li> */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink6" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Committees
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {/* <li><Link to ="/Founding" className="dropdown-item">Founding EC Member</Link></li> */}
                                        <li><Link to ="/Member" className="dropdown-item">Founding EC Team</Link></li>
                                        <li><Link to ="/Current" className="dropdown-item">Current EC Team</Link></li>
                                        <li><Link to ="/ECMemberHistory" className="dropdown-item">EC Member History</Link></li>
                                        {/* <li><Link to ="/Acemember" className="dropdown-item" >ACE Member</Link></li>
                                        <li><Link to ="/Yesmember" className="dropdown-item">YES Member</Link></li> */}
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                <Link to ="/Member" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink6" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Member
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li><Link to ="/List" class="dropdown-item" >Chamber Member</Link></li>
                            <li><Link to ="/AssociateMemberList" class="dropdown-item" >Associate Member</Link></li>
                            <li><Link to ="/HoneryMemberList" class="dropdown-item" >Honorary Member</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><Link to="/Events" className="nav-link">Events</Link></li>
                                <li><Link to ="/Gallery"  className="nav-link">Gallery</Link></li>
                            </ul>
                            <ul className="navbar-nav ml-auto">
                            {isAuthenticated ? (
                                <>
    <li className="nav-item">
        <Link to="/UserProfileShow" href="registration.html" className="nav-link link-color">
            <i className="flaticon-plus"></i>Profile
        </Link>
    </li>
    <li className="nav-item">
    <Link to="/Home"  className="nav-link link-color" onClick={handleLogout}>
        <i className="flaticon-plus"></i>Logout
    </Link>
</li>
</>
) : (
    <>
        <li className="nav-item">
            <Link to="/Login" className="nav-link">
                <i className="flaticon-male"></i>Login
            </Link>
        </li>
        <li className="nav-item">
            <Link to="/Register" href="registration.html" className="nav-link link-color">
                <i className="flaticon-plus"></i>New Member
            </Link>
        </li>
    </>
)}

                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            {/* <div className="sub-banner">
                <div className="container breadcrumb-area">
                </div>
            </div> */}
        </div>
    );

}
export default Header;