import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import Header from "../header";
import Footer from "../footer";
import Image from "../img/events/blog-1.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from 'dompurify';

function Events() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/event/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate("/login");
        return; // Exit early to prevent further logic execution
      }
      const eventIds = response.data.map((event) => event.eventid);
      localStorage.setItem("eventIds", JSON.stringify(eventIds));
      const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const handleEventClick = (eventid) => {
    localStorage.setItem("clickedEventId", JSON.stringify(eventid));
    // Navigate to the specific event page using the event ID
    navigate(`/Eventdetails/${eventid}`);
  };
  const toggleReadMore = () => {
    setShowReadMore(!showReadMore);
  };
  return (
    <div>
      <Header />
      <div class="blog-body content-area">
        <div class="container">
          {loading ? (
            <div className="text-center">
              <p>Loading...</p>
              {/* You can use a loading spinner here if you have one */}
            </div>
          ) : (
            <div class="row" >
              <p><strong>An event is the best platform to convey the required information to the targetted audience. NCC conducts events to communicate its ideas in India and overseas. We also use Social Media to attract people beyond our target!!</strong></p>
              {data.map((event) => (
                <div class="col-lg-4 col-md-6" key={event.eventid}>
                  <div class="blog-2" onClick={() => handleEventClick(event.eventid)}>
                    <div class="blog-photo">
                      <img
                        src={event.eventimage}
                        alt="small-blog"

                      />
                      {event.date && <div class="date-box">
                        <span>{formatDate(event.date)}</span>
                      </div>}
                    </div>
                    <div class="detail">
                      <h4 onClick={() => handleEventClick(event.eventid)}>
                        {event.title}
                      </h4>
                      <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(showReadMore ? event.description : `${event.description.substring(0, 120)}...`),}}></p>
                      <div className="d-flex align-items-end"> <button
                        type="submit"
                        className="btn-md button-theme "

                        onClick={toggleReadMore}
                      >
                        Read More
                      </button></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* <!-- Page navigation start --> */}
          {/* <div class="pagination-box hidden-mb-45 text-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <i class="fa fa-angle-left"></i>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link active" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    href="properties-list-leftsidebar.html"
                  >
                    <i class="fa fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Events;
