import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../App.css';
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/default.css";
import "../css/slick.css";
import image from "../img/logos/logo.png";
import { Link } from "react-router-dom";
import {  notification } from "antd";

import banner from "../img/banner/banner-4.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";

function Login() {
  const [mobile, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();

  const handleMobileChangeLogin = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setUsername(newValue);
    }
  };
  const handleLogin = async () => {
    console.log("Mobile:", mobile);
  console.log("Password:", password);
    try {
      const response = await fetch(   
        "https://api.nagaratharcoc.com/api/v1/login/authenticateclient",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if(data.token!=null){
        localStorage.setItem("token", data.token);
        // After successful login, close the login popup
         notification.success({
            message: 'Welcome to Nagarathar you successfully logged in',
            duration: 3, 
            placement: 'bottomRight'
          })
        navigate(-1);
        }else{
          setResponseMessage(data.message);
                }
        // After successful login, make the API call to get client details
        const getClientDetailsResponse = await fetch(
          "https://api.nagaratharcoc.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );

        if (getClientDetailsResponse.ok) {
          const clientData = await getClientDetailsResponse.json();
          // Now you can use clientData for further actions or state updates
          console.log("Client Data:", clientData);
        } else {
          console.error("Failed to get client details");
        }
      } else {
        console.error("Client Login failed");
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div>
      
      <Header/>
     
    <div class="">
    <div class="container">
    <div class="row">
        <div class="col-lg-12">
            {/* <!-- Form content box start --> */}
            <div class="form-content-box" >
                {/* <!-- details --> */}
                <div class="details">
                    {/* <!-- Logo --> */}
                    {/* <!-- Name --> */}
                    <h3>Sign into your account</h3>
                    {/* <!-- Form start --> */}
                    <form onSubmit={(e) => e.preventDefault()} >
                        <div class="form-group">
                            <input  type="tel" name="text" class="input-text" placeholder="Mobile no." value={mobile}
                   onChange={handleMobileChangeLogin}/>
                   { mobile.length < 10 && mobile.length > 0 && (
  <div className="error-message" style={{color:"red"}}>Please enter 10 digits</div>
)}
                        </div>
                        <div class="form-group">
                            <input type="password" name="Password" class="input-text" placeholder="Password" value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div class="form-group mb-0">
                            <button type="submit" class="btn-md button-theme btn-block" onClick={handleLogin}>login</button>
                        </div>
                        <div class="row" style={{marginTop:"10px",fontSize:'14px'}}>
                          <div class="col-md-6">
                        <Link to="/Register" class="link-not-important pull-right" style={{textDecoration:'none',color:'#212529',float:'left'}}>New Member</Link></div>
                        <div class="col-md-6">
                            <Link to="/Yesmember" class="link-not-important pull-right" style={{textDecoration:'none',color:'#212529',float:'right'}}>Forgot Password</Link></div>
                        </div>
                        {responseMessage && (
        <div className="response-message" style={{ color: "red" }}>
          {responseMessage}
        </div>
      )}
                    </form>
                  
                </div>
                
        </div>
    </div>
</div>    
    </div>
    </div>
    
    <Footer/>
    </div>
    
  );
}

export default Login;
