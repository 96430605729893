import React from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the styles
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import Home from "../src/home/index"
import Header from "../src/header/index";
import Login from '../src/login/index';
import Member from './memberdetails/index';
import Acemember from '../src/acemember/index';
import Contact from '../src/contact/index';
import Current from '../src/current/index';
import Eventdetails from '../src/eventdetails/index';
import EventGallery from '../src/eventGallery/index';
import Events from '../src/event/index';
import Founding from '../src/founding/index';
import Index from '../src/about/index';
import List from '../src/list/index';
import AssociateMemberList from '../src/associatemember/index';
import HoneryMemberList from '../src/honerarymemeber/index';
import Membertest from '../src/membertest/index'
import Register from '../src/register/index';
import Registarion from '../src/registration/index';
import Footer from '../src/footer/index';
import Byelaw from './byelaw/index';
import Yesmember from './yesmember';
import About from '../src/about/index';
import Successful from './successful';
import UserProfile from './userProfile';
import ProfileImage from './proimageupload';
import UserProfileShow from "././proimageupload/userProfileShow";
import Gallery from "../src/eventGallery/gallery";



function App() {
  return (
    <Router>
      <Routes>
      <Route index element={<Home />} />
       <Route path="/Login" element={<Login />} />
       <Route path="/Header" element={<Header />} />
      <Route path="/Registarion" element={<Registarion />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/Byelaw" element={<Byelaw />} />
      <Route path="/Yesmember" element={<Yesmember />} />
      <Route path="/Member" element={<Member />} />
      <Route path="/Acemember" element={<Acemember />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Current" element={<Current />} />
      <Route path="/Eventdetails/:eventid" element={<Eventdetails />} />
      <Route path="/EventGallery/:eventid" element={<EventGallery />} />
      <Route path="/Events" element={<Events />} />
      <Route path="/ECMemberHistory" element={<Founding />} />
      <Route path="/Index" element={<Index />} />
      <Route path="/List" element={<List />} />
      <Route path="/AssociateMemberList" element={<AssociateMemberList />} />
      <Route path="/HoneryMemberList" element={<HoneryMemberList />} />
      <Route path="/Reference/:referenceid" element={<Membertest />} />
      <Route path="/Footer" element={<Footer />} />
      <Route path="/UserProfile" element={<UserProfile />} />
      <Route path="/ProfileImage" element={<ProfileImage />} />
      <Route path="/About" element={<About />} />
      <Route path="/Successful" element={<Successful />} />
      <Route path="/UserProfileShow" element={<UserProfileShow />} />
      <Route path="/Gallery" element={<Gallery />} />
      
      </Routes>
    </Router>
  );

}

export default App;
