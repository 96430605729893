import React from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import Header from "../header";
import Footer from "../footer";


function Successful() {
    return(
        <div>
            <Header/>
        <div class="container">
        <div class="dashboard-lists">
            <h3 class="main-title">Thank you for Registeration</h3><br/>
            <h3 class="main-title">Admin will contact you soon.</h3><br/>
            <h3 class="main-title">If you are not paying, please pay first.</h3><br/>
        </div>
        </div>
        <Footer/>
        </div>
        )
}
export default Successful;