import React, { useState, useEffect  } from "react";
import '../App.css';
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/default.css";
import "../css/slick.css";
import Image from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import axios from "axios";
import { Select, Upload, Button, Form, message,DatePicker,notification } from 'antd';
import { useNavigate } from "react-router-dom";
import { UploadOutlined  } from '@ant-design/icons';
import noImage from "../img/noimage.jpg";
import moment from 'moment';
const { Option } = Select;

function ProfileImage() {
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]); // To store uploaded files
    const [clientDetails, setClientDetails] = useState(null); // To store client details
    const [userData, setUserData] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [pincodeList, setPincodeList] = useState([]);
    const [pincode, setPincode] = useState(null);
    const [nativee, setNative] = useState("");
    const [kovil, setKovil] = useState('');
    const [perivu, setPerivu] = useState([]);
    const [selectedPerivu, setSelectedPerivu] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [spousenativee, setSpousenativee] = useState("");
    const [spousekovil, setSpousekovil] = useState("");
    const [spouseperivu, setSpouseperivu] = useState([]);
    const [selectedPerivuforspouse, setSelectedPerivuforspouse] = useState("");
    const [fathersnativee, setFathernativee] = useState("");
    const [fatherskovil, setFatherkovil] = useState("");
    const [fathersperivu, setFatherperivu] = useState([]);
    const [selectedPerivuforfather, setSelectedPerivuforfather] = useState("");
    const [companycategory, setCompanycategory] = useState("");
	  const [data, setData] = useState([]);
    const [companymobile, setCompanymobile] = useState("");
    const [isValidEmailforcomapny, setIsValidEmailforcomapny] = useState(true);
    const [companyemail, setCompanyemail] = useState("");
    const [selectedCountryforcompany, setSelectedCountryforcompany] = useState("");
    const [companycountryList, setCompanyCountryList] = useState([]);
    const [selectedStateforcompany, setSelectedStateforcompany] = useState("");
    const [companystateList, setCompanyStateList] = useState([]);
    const [companypincodeList, setCompanyPincodeList] = useState([]);
    const [companypincode, setCompanypincode] = useState(null);
    const [referedby, setReferedby] = useState([])
    const [selectedMemberType, setSelectedMemberType] = useState([]);
    const [membertype, setMembertype] = useState([]);

    const getClientDetails = async () => {
      try {
          const response = await axios.post(
              "https://api.nagaratharcoc.com/api/v1/login/getclientdetails",
              {},
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                      'Content-Type': 'application/json',
                  },
              }
          );
  
          if (response.status === 401 || response.status === 404) {
              // Redirect to the login page for 401 or 404 errors
              navigate('/login');
              return; // Exit early to prevent further logic execution
          }
  
          // Handle the response data as needed
          const idFromResponse = response.data.id;
          if(idFromResponse!=null){
            const fetchUserDetails = async () => {
                try {
                  const response = await axios.post(
                    "https://api.nagaratharcoc.com/api/v1/client/getbyid",
                    { clientid: parseInt(idFromResponse )},
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );
        
                  // Set the user data in state
                  setUserData(response.data);
                } catch (error) {
                  console.error("Error fetching user details:", error);
                }
              };
        
              fetchUserDetails();
          }
          // Store the id in localStorage
          localStorage.setItem('clientid', idFromResponse);
  
          // Set the id in the state
          setClientDetails(response.data);
      } catch (error) {
          console.error("Error fetching client details:", error);
      }
  };
  

    useEffect(() => {
     
        // Fetch client details when the component mounts
        getClientDetails();
    }, []);
  const handleEditButtonClick = () => {
    setIsEditMode(!isEditMode); 
    if ( selectedCountry!=null || userData?.country!=null && stateList==null){
      fetchStates(userData.country!=null ?userData.country   : selectedCountry)
    }
    if(selectedState!=null || userData?.state!=null && pincodeList==null){
      
      fetchPincodes(userData.country!=null ?userData.country   : selectedCountry,userData.state!=null ?userData.state   : selectedState)
    }
    if ( selectedCountryforcompany!=null || userData?.companycountry!=null && companystateList==null){
      fetchStatesforCompany(userData.companycountry!=null ?userData.companycountry   : selectedCountryforcompany)
    }
    if(selectedStateforcompany!=null || userData?.companystate!=null && companypincodeList==null){
      
      fetchPincodesforCompany(userData.companycountry!=null ?userData.companycountry   : selectedCountryforcompany,userData.companystate!=null ?userData.companystate   : selectedStateforcompany)
    }
    if (userData.kovil != null) {
      handleKovilChange(userData.kovil); // Set perivu based on userData.kovil
  }
  if (userData.spousekovil != null) {
    handleKovilChangeforspouse(userData.spousekovil);
  }
  if (userData.fatherskovil != null) {
    handleKovilChangeforfather(userData.fatherskovil);
  }
  };

  const handleSaveButtonClick = async () => {
    try {
      const selectedClientIds = referedby;
      console.log("Selected Pincode:", pincode);
      // Make API call to update user data
      const editProfileResponse = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/client/editClientDetails",
        {
          clientid: localStorage.getItem("clientid"),
          firstname: userData.firstname,
        lastname: userData.lastname,
        gender: userData.gender,
        maritalstatus: userData.maritalstatus,
        dateofbirth: userData.dateofbirth,
        address1: userData.address1,
        email: userData.email,
        password: userData.password,
        dateofbirth: userData.dateofbirth,
        country : selectedCountry ? selectedCountry : userData.country,
        state : selectedState ? selectedState : userData.state,
        city: userData.city,
        area: userData.area,
        nativee: nativee!="" ? nativee : userData.nativee,
        kovil:kovil!="" ? kovil : userData.kovil,
        perivu:selectedPerivu ?selectedPerivu : userData.perivu ,
        pincode: pincode,
        mobile: userData.mobile,
        spousename: userData.spousename,
        spousenativee: spousenativee!="" ? spousenativee : userData.spousenativee,
        spousekovil: spousekovil!="" ? spousekovil : userData.spousekovil,
        spouseperivu:selectedPerivuforspouse ? selectedPerivuforspouse : userData.spouseperivu,
        fathersname: userData.fathersname,
        fathersnativee:fathersnativee!="" ? fathersnativee : userData.fathersnativee,
        fatherskovil: fatherskovil!="" ? fatherskovil : userData.fatherskovil,
        fathersperivu:selectedPerivuforfather? selectedPerivuforfather : userData.fathersperivu ,
        companyname: userData.companyname,
        companycategory: companycategory!="" ? companycategory :userData.companycategory,
        companylandline: userData.companylandline,
        companymobile:companymobile!="" ? companymobile: userData.companymobile,
        companyemail:companyemail!="" ? companyemail : userData.companyemail,
        companywebsite: userData.companywebsite,
        companyaddress1: userData.companyaddress1,
        companycountry : selectedCountryforcompany ?selectedCountryforcompany : userData.companycountry,
        companystate : selectedStateforcompany? selectedStateforcompany : userData.companystate,
        companycity: userData.companycity,
        companyarea: userData.companyarea,
        companypincode: companypincode,
        designation: userData.designation,
        proposername: userData.proposername,
        yearofjoining: userData.yearofjoining,
        secordorname: userData.secordorname,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (editProfileResponse.status === 200) {
        // Reload the page to reflect the changes
        window.location.reload();
      }

      console.log("Edit Profile Response:", editProfileResponse.data);
      message.success("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile");
    }
  };
  
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "https://api.nagaratharcoc.com/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);
  const handleCountryClick = (selectedValue) => {
    console.log("Selected Country:", selectedValue);
    // You can use the selectedValue as the selected country here
    setSelectedCountry(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStates(selectedValue);
    }
  };
  const fetchStates = async (selectedCountry) => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/pincode/getstate",
        { country : selectedCountry },
        {
          headers: {  
            "Content-Type": "application/json",
          },
        }
      );

      // Set the fetched states in state
      if (response.status === 200) {
        setStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleStateClick = (selectedValue1) => {
    // You can use the selectedValue as the selected country here
    setSelectedState(selectedValue1);

    // Make the API call to fetch states based on the selected country
    if (selectedValue1) {
      fetchPincodes(selectedCountry, selectedValue1);
    }
  };
  const fetchPincodes = async (selectedCountry, selectedState) => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/pincode/getpincode",
        {
          country: selectedCountry,
          state: selectedState,
        }
      );

      if (response.status === 200) {
        console.log("Pincode Response:", response.data);
        setPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const handleDropdownScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
  
    // Fetch more pin codes based on the current selectedCountry and selectedState
    if (scrollTop + clientHeight === scrollHeight) {
      try {
        const response = await axios.put(
          "https://api.nagaratharcoc.com/api/v1/pincode/getpincode",
          {
            country: selectedCountry,
            state: selectedState,
            // Add proper pagination parameters here
            // For example: page, pageSize, etc.
          }
        );
  
        if (response.status === 200) {
          const newPincodeList = response.data;
  
          // Update pincode list state without duplicates
          setPincodeList((prevPincodeList) => [
            ...new Set([...prevPincodeList, ...newPincodeList]),
          ]);
        } else {
          console.error("Failed to get the list of pincodes");
        }
      } catch (error) {
        console.error("Error fetching the list of pincodes:", error);
      }
    }
  };
 
const fetchDataforcategory = async () => {
  try {
    const response = await axios.put("https://api.nagaratharcoc.com/api/v1/companycategory/get",{},{
    
    headers: {
    'Content-Type': 'application/json',
    },
    });
    if (response.status === 401 || response.status === 404) {
    navigate('/login');
    return; 
    }
    setData(response.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  };

  useEffect(() => {
    fetchDataforcategory();
    }, []); 
    const handleMobileChangeLoginforComapny = (e) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue) && newValue.length <= 10) {
        setCompanymobile(newValue);
      }
    };
    const handleEmailChangeforcompany = (event) => {
      const newEmail = event.target.value;
      setCompanyemail(newEmail);
  
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(newEmail);
      setIsValidEmailforcomapny(isValid);
    };
    const handleCountryClickforCompany = (selectedValueforcompany) => {
      console.log("Selected Country:", selectedValueforcompany);
      // You can use the selectedValue as the selected country here
      setSelectedCountryforcompany(selectedValueforcompany);
  
      // Make the API call to fetch states based on the selected country
      if (selectedValueforcompany) {
        fetchStatesforCompany(selectedValueforcompany);
      }
    };
    useEffect(() => {
      const fetchCountryListforCompany = async () => {
        try {
          const response = await fetch(
            "https://api.nagaratharcoc.com/api/v1/pincode/getcountry",
            {
              method: "PUT",
            }
          );
  
          if (response.ok) {
            const data = await response.json();
            setCompanyCountryList(data);
          } else {
            console.error("Failed to get the list of countries");
          }
        } catch (error) {
          console.error("Error fetching the list of countries:", error);
        }
      };
  
      fetchCountryListforCompany();
    }, []);
    const handleStateClickforCompany = (selectedValueforcompany1) => {
      // You can use the selectedValue as the selected country here
      setSelectedStateforcompany(selectedValueforcompany1);
  
      // Make the API call to fetch states based on the selected country
      if (selectedValueforcompany1) {
        fetchPincodesforCompany(selectedCountryforcompany, selectedValueforcompany1);
      }
    };
    const fetchStatesforCompany = async (selectedCountryforcompany) => {
      try {
        const response = await axios.put(
          "https://api.nagaratharcoc.com/api/v1/pincode/getstate",
          { country : selectedCountryforcompany },
          {
            headers: {  
              "Content-Type": "application/json",
            },
          }
        );
  
        // Set the fetched states in state
        if (response.status === 200) {
          setCompanyStateList(response.data);
        } else {
          console.error("Failed to get the list of states");
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    const fetchPincodesforCompany = async (selectedCountryforcompany, selectedStateforcompany) => {
      setCompanyPincodeList([]);
      try {
        const response = await axios.put(
          "https://api.nagaratharcoc.com/api/v1/pincode/getpincode",
          {
            country: selectedCountryforcompany,
            state: selectedStateforcompany,
          }
        );
  
        if (response.status === 200) {
          setCompanyPincodeList(response.data);
        } else {
          console.error("Failed to get the list of pincodes");
        }
      } catch (error) {
        console.error("Error fetching the list of pincodes:", error);
      }
    };
    useEffect(() => {
      const fetchDataforreferedby = async () => {
        try {
          const response = await fetch(
            "https://api.nagaratharcoc.com/api/v1/client/getreference",{
              method : 'put',
              headers: {
                  'Content-Type': 'application/json',
                },
            }
          );
          if (response.ok) {
            const data = await response.json();
            const referedByNames = data.map((client) => ({
              clientidid: client.clientid,
              name: client.name || "Unknown",
              companyname: client.companyname || "Unknown"
            }));
            setReferedby(referedByNames.filter(Boolean)); 
          } else {
            console.error("Failed to fetch data from the API");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchDataforreferedby(); 
    }, []);
    const handleSelectChange = (selectedValues) => {
      if (selectedValues.length > 2) {
        return;
      }
  
      setSelectedMemberType(selectedValues);
    };
    const nativeOptions = [
      "Alagapuri (Kottaiyur)",
      "Alavakottai",
      "Amaravathipudur",
                                      "Aranmanai Siruvayal",
                                     " Arimalam",
                                     " Ariyakudi",
                                      "Athikkadu Thekkur",
                                      "Attangudi",
                                      "Attangudi Muthupattinam",
                                      'Avanipatti',
                                      'Chockalingam Pudur',
                                      'Alagapuri (Kottaiyur)',
                                     'Alavakottai',
                                       "Amaravathipudur",
                                       'Aranmanai Siruvayal',
                                      'Arimalam',
                                      'Ariyakudi',
                                       'Athikkadu Thekkur',
                                       'Attangudi',
                                       'Attangudi Muthupattinam',
                                       "Avanipatti",
                                       'Chockalingam Pudur',
                                       'Chocknathapuram',
                                     ' Devakottai',
                                      ' Kaliarmangalam',
                                       'Kallal',
                                       'Kandavarayanpatti',
                                       'Kandanur',
                                      'Karaikudi',
                                      "Karunkulam",
                                       'Kilapungudi',
                                      ' Kilasivalpatti',
                                      " P.Alagapuri",
                                      " Kollangudi Alagapuri",
                                       "Konapet",
                                       'Koppanapatti',
                                       "Kottamangalam",
                                      'Kottaiyur',
                                      "Kulipirai",
                                      "Kuruvikondanpatti",
                                      "Lakshmipuram",
                                      "Kothamangalam Lakshipuram",
                                      'Madaguppatti',
                                      "Chockalingapuram",
                                      "Mahibalanpatti",
                                      'Managiri',
                                      'Melasivapuri',
                                      'Mithalaipatti',
                                      'Nachundupatti',
                                      'Nachiapuram',
                                      'Natarajapuram',
                                      'Nemathanpatti',
                                       'Nerkupai',
                                       'Okkur',
                                      "Oyakondan Siruvayal",
                                      'Panageri',
                                      'Palavangudi',
                                       'Pallathur',
                                       'Panagudi',
                                       'Panayapatti',
                                       'Pariamaruthapatti',
                                       'Pattamangalam',
                                       'Pillaiyarpatti',
                                      'Ponnamaravathy',
                                      'Pulankurichi',
                                      'Pudupatti (Ponnamaravathy)',
                                      'Puduvayal',
                                       'Ramachandrapuram',
                                       'Rangiam',
                                       'Rajavaram',
                                       'Sakkandi',
                                       'Sembanur',
                                      'Sevvur',
                                      'Shanmuganathapuram (Aravayal)',
                                       'Siravayal',
                                       'Sirukudalpatti',
                                       'Solapuram',
                                       'Thanichavurani',
                                       'Thenipatti',
                                      'Ulagampatti',
                                      'Valayapatti',
                                      'Vegupatti',
                                      'Veendhampatti',
                                       'Vetriyur',
                                      "Virachalai",
                                       'Viramathi (Kilasivalpatti)',
                                      "V.Lakshipuram (Virachalai)"
  ];
  const handleKovilChange = (selectedKovilValue) => {
    setKovil(selectedKovilValue);
  
    // Set perivuOptions based on selected Kovil
    switch (selectedKovilValue) {
      case 'Vairavan Kovil':
        setPerivu([" Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setPerivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
        case 'Mathur':
            setPerivu(["Uraiyur", "Arumbakkur", " Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setPerivu([]);
    }
  };
  const handlePerivuChange = (e) => {
    setSelectedPerivu(e);
  };  
  const handleMobileChangeLogin = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setMobile(newValue);
    }
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(newEmail);
    setIsValidEmail(isValid);
  }; 
  const nativeOptionsforSpouse = [
    "Alagapuri (Kottaiyur)",
    "Alavakottai",
    "Amaravathipudur",
                                    "Aranmanai Siruvayal",
                                   " Arimalam",
                                   " Ariyakudi",
                                    "Athikkadu Thekkur",
                                    "Attangudi",
                                    "Attangudi Muthupattinam",
                                    'Avanipatti',
                                    'Chockalingam Pudur',
                                    'Alagapuri (Kottaiyur)',
                                   'Alavakottai',
                                     "Amaravathipudur",
                                     'Aranmanai Siruvayal',
                                    'Arimalam',
                                    'Ariyakudi',
                                     'Athikkadu Thekkur',
                                     'Attangudi',
                                     'Attangudi Muthupattinam',
                                     "Avanipatti",
                                     'Chockalingam Pudur',
                                     'Chocknathapuram',
                                   ' Devakottai',
                                    ' Kaliarmangalam',
                                     'Kallal',
                                     'Kandavarayanpatti',
                                     'Kandanur',
                                    'Karaikudi',
                                    "Karunkulam",
                                     'Kilapungudi',
                                    ' Kilasivalpatti',
                                    " P.Alagapuri",
                                    " Kollangudi Alagapuri",
                                     "Konapet",
                                     'Koppanapatti',
                                     "Kottamangalam",
                                    'Kottaiyur',
                                    "Kulipirai",
                                    "Kuruvikondanpatti",
                                    "Lakshmipuram",
                                    "Kothamangalam Lakshipuram",
                                    'Madaguppatti',
                                    "Chockalingapuram",
                                    "Mahibalanpatti",
                                    'Managiri',
                                    'Melasivapuri',
                                    'Mithalaipatti',
                                    'Nachundupatti',
                                    'Nachiapuram',
                                    'Natarajapuram',
                                    'Nemathanpatti',
                                     'Nerkupai',
                                     'Okkur',
                                    "Oyakondan Siruvayal",
                                    'Panageri',
                                    'Palavangudi',
                                     'Pallathur',
                                     'Panagudi',
                                     'Panayapatti',
                                     'Pariamaruthapatti',
                                     'Pattamangalam',
                                     'Pillaiyarpatti',
                                    'Ponnamaravathy',
                                    'Pulankurichi',
                                    'Pudupatti (Ponnamaravathy)',
                                    'Puduvayal',
                                     'Ramachandrapuram',
                                     'Rangiam',
                                     'Rajavaram',
                                     'Sakkandi',
                                     'Sembanur',
                                    'Sevvur',
                                    'Shanmuganathapuram (Aravayal)',
                                     'Siravayal',
                                     'Sirukudalpatti',
                                     'Solapuram',
                                     'Thanichavurani',
                                     'Thenipatti',
                                    'Ulagampatti',
                                    'Valayapatti',
                                    'Vegupatti',
                                    'Veendhampatti',
                                     'Vetriyur',
                                    "Virachalai",
                                     'Viramathi (Kilasivalpatti)',
                                    "V.Lakshipuram (Virachalai)"
  ];
  const handleKovilChangeforspouse = (selectedKovilValueforspouse) => {
    setSpousekovil(selectedKovilValueforspouse);
  
    // Set perivuOptions based on selected Kovil
    switch (selectedKovilValueforspouse) {
      case 'Vairavan Kovil':
        setSpouseperivu([" Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setSpouseperivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
        case 'Mathur':
            setSpouseperivu(["Uraiyur", "Arumbakkur", " Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setSpouseperivu([]);
    }
  };
  const handlePerivuChangeforspouse = (e) => {
    setSelectedPerivuforspouse(e);
  };
  const nativeOptionsforFather = [
    "Alagapuri (Kottaiyur)",
    "Alavakottai",
    "Amaravathipudur",
                                    "Aranmanai Siruvayal",
                                   " Arimalam",
                                   " Ariyakudi",
                                    "Athikkadu Thekkur",
                                    "Attangudi",
                                    "Attangudi Muthupattinam",
                                    'Avanipatti',
                                    'Chockalingam Pudur',
                                    'Alagapuri (Kottaiyur)',
                                   'Alavakottai',
                                     "Amaravathipudur",
                                     'Aranmanai Siruvayal',
                                    'Arimalam',
                                    'Ariyakudi',
                                     'Athikkadu Thekkur',
                                     'Attangudi',
                                     'Attangudi Muthupattinam',
                                     "Avanipatti",
                                     'Chockalingam Pudur',
                                     'Chocknathapuram',
                                   ' Devakottai',
                                    ' Kaliarmangalam',
                                     'Kallal',
                                     'Kandavarayanpatti',
                                     'Kandanur',
                                    'Karaikudi',
                                    "Karunkulam",
                                     'Kilapungudi',
                                    ' Kilasivalpatti',
                                    " P.Alagapuri",
                                    " Kollangudi Alagapuri",
                                     "Konapet",
                                     'Koppanapatti',
                                     "Kottamangalam",
                                    'Kottaiyur',
                                    "Kulipirai",
                                    "Kuruvikondanpatti",
                                    "Lakshmipuram",
                                    "Kothamangalam Lakshipuram",
                                    'Madaguppatti',
                                    "Chockalingapuram",
                                    "Mahibalanpatti",
                                    'Managiri',
                                    'Melasivapuri',
                                    'Mithalaipatti',
                                    'Nachundupatti',
                                    'Nachiapuram',
                                    'Natarajapuram',
                                    'Nemathanpatti',
                                     'Nerkupai',
                                     'Okkur',
                                    "Oyakondan Siruvayal",
                                    'Panageri',
                                    'Palavangudi',
                                     'Pallathur',
                                     'Panagudi',
                                     'Panayapatti',
                                     'Pariamaruthapatti',
                                     'Pattamangalam',
                                     'Pillaiyarpatti',
                                    'Ponnamaravathy',
                                    'Pulankurichi',
                                    'Pudupatti (Ponnamaravathy)',
                                    'Puduvayal',
                                     'Ramachandrapuram',
                                     'Rangiam',
                                     'Rajavaram',
                                     'Sakkandi',
                                     'Sembanur',
                                    'Sevvur',
                                    'Shanmuganathapuram (Aravayal)',
                                     'Siravayal',
                                     'Sirukudalpatti',
                                     'Solapuram',
                                     'Thanichavurani',
                                     'Thenipatti',
                                    'Ulagampatti',
                                    'Valayapatti',
                                    'Vegupatti',
                                    'Veendhampatti',
                                     'Vetriyur',
                                    "Virachalai",
                                     'Viramathi (Kilasivalpatti)',
                                    "V.Lakshipuram (Virachalai)"
  ];
  const handleKovilChangeforfather = (selectedKovilValueforfather) => {
    setFatherkovil(selectedKovilValueforfather);
  
    // Set perivuOptions based on selected Kovil
    switch (selectedKovilValueforfather) {
      case 'Vairavan Kovil':
        setFatherperivu([" Sirukulattur", "Kalanivasal", "Maruttendrapuram"]);
        break;
      case 'Ilayathakudi':
        setFatherperivu(["Okkur", "Arumburkolar (alias Pattanasamy)", "Perumarudur", "Kinkanikkur", "Kalanivasal", "Perasandur", "Sirusettur"]);
        break;
        case 'Mathur':
            setFatherperivu(["Uraiyur", "Arumbakkur", " Manalur", "Mannur", "Kannur", "Karuppur", "Kulattur"]);
        break;
      default:
        setFatherperivu([]);
    }
  };
  const handlePerivuChangeforfather = (e) => {
    setSelectedPerivuforfather(e);
  };
  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const maxFileSize = 1024 * 1024;
		if (file.size > maxFileSize) {
			notification.error({
				message: 'Please upload an image with a size less than 1MB.',
			});
			return;
		}
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await axios.post(
            "https://api.nagaratharcoc.com/api/v1/fileupload/file",
            formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for file uploads
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress({ percent: percentCompleted });
                },
            }
        );

        if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/login');
            return; // Exit early to prevent further logic execution
        }

        // Check if the response contains the 'organiserId'
        if (response.data && response.data.organiserId) {
            const organiserId = response.data.organiserId;

            // Store the updated organiserIds array back in local storage
            localStorage.setItem('organiserId', organiserId);
        }

        localStorage.removeItem('organiserIds');
        message.success("File uploaded successfully");
        onSuccess("File uploaded");

        // After successful file upload, make a subsequent API call
        
        const editProfileImageResponse = await axios.post(
            "https://api.nagaratharcoc.com/api/v1/client/editprofileimage",
            {
              clientid:localStorage.getItem('clientid'), 
              fileid:localStorage.getItem('organiserId'),
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );if (editProfileImageResponse.status === 200) {
    // Reload the page to reflect the changes
    window.location.reload();
  }

        // Handle the response of the editprofileimage API call as needed
        console.log("Edit Profile Image Response:", editProfileImageResponse.data);
    } catch (error) {
        // message.error("File upload failed");
        onError("File upload failed");
    }
};
return(
        <div>        
        <Header/>
    <div class="our-team content-area">
    <div class="container">
<div class="dashboard-list" >
    <h3 class="main-title">Personal Details
    </h3>
    <div class="dashboard-message contact-2 bdr clearfix">
        <div class="row">
        <div class="col-lg-3 col-md-3">
            <div class="edit-profile-photo">
              <img src={(userData?.profilepic) || { noImage }} alt="profile-photo" class="img-fluid" />
              {isEditMode && (
                <Form.Item name="uploadfile">
                  <Upload
                    customRequest={handleFileUpload}
                    fileList={fileList}
                    onChange={({ fileList }) => setFileList(fileList)}
                  >
                    <Button className="photoUpload" icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>
                      Upload Photo
                    </Button>
                  </Upload>
                </Form.Item>
              )}
            </div>
          </div>
            <div class="col-lg-9 col-md-9">
                <form action="#" enctype="multipart/form-data"
                >
                    <div class="row">
                    <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Your Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.firstname || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              firstname: e.target.value,
            }))
          }/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Last Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.lastname || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              lastname: e.target.value,
            }))
          }/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
    <div class="form-group">
        <label>Gender</label>
            <Select
            style={{width:"100%"}}
                value={userData?.gender || undefined}
                onChange={(value) =>
                    setUserData((prevData) => ({
                        ...prevData,
                        gender: value,
                    }))
                }
            >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
            </Select>
    </div>
</div>
<div class="col-lg-6 col-md-6">
    <div class="form-group">
        <label>Marital Status</label>
            <Select
            style={{width:"100%"}}
                value={userData?.maritalstatus || undefined}
                onChange={(value) =>
                    setUserData((prevData) => ({
                        ...prevData,
                        maritalstatus: value,
                    }))
                }
            >
                <Select.Option value="single">Single</Select.Option>
                <Select.Option value="married">Married</Select.Option>
                <Select.Option value="widowed">Widowed</Select.Option>
                <Select.Option value="divorced">Divorced</Select.Option>
            </Select>
    </div>
</div>
<div class="col-lg-6 col-md-6">
    <div class="form-group subject">
        <label>DOB</label>
            <DatePicker
            style={{width:"100%"}}
                value={userData?.dateofbirth ? moment(userData.dateofbirth) : null}
                onChange={(date, dateString) =>
                    setUserData((prevData) => ({
                        ...prevData,
                        dateofbirth: dateString,
                    }))
                }
            />
    </div>
</div>

<div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Address 1</label>
        <input
            type="text"
            name="text"
            class="form-control"
            value={userData?.address1 || ""}
            onChange={(e) =>
                setUserData((prevData) => ({
                    ...prevData,
                    address1: e.target.value,
                }))
            }
        />
    </div>
</div>

<div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Country</label>
        {isEditMode ? (
            <div style={{ position: 'relative' }}>
                <Select
                    placeholder="Select country"
                    value={selectedCountry?selectedCountry:(userData?.country) || ""}
                    style={{ width: '100%', height: "55px" }}
                    onChange={(value) => handleCountryClick(value)}
                >
                    {countryList.map((country) => (
                        <Option key={country.pincodeid} value={country.country}>
                            {country.country}
                        </Option>
                    ))}
                </Select>
            </div>
        ) : (
          <input
          type="text"
          name="text"
          class="form-control"
          value={(userData?.country) || ""}
      />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>State</label>
        {isEditMode ? (
            <Select
                className="form-control"
                name="state"
                required
                value={selectedState?selectedState:(userData?.state) || ""}
                onChange={(value) => handleStateClick(value)}
                style={{ width: '100%' }}
                mode="default" 
            >
                {stateList.map((stateItem) => (
                    <Option key={stateItem.pincodeid} value={stateItem.state}>
                        {stateItem.state}
                    </Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="text"
                class="form-control"
                value={userData?.state || ""}
            />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Pincode</label>
        {isEditMode ? (
            <Select
                style={{ width: "100%" }}
                showSearch
                onPopupScroll={handleDropdownScroll}
                placeholder="Select pincode"
                value={pincode?pincode:(userData?.pincode) || ""}
                onChange={(value) => setPincode(value)}
                optionLabelProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {pincodeList.map((pincode, index) => (
                    <Select.Option key={pincode.pincodeid || index} value={pincode.pincode}>
                        {pincode.pincode}
                    </Select.Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="text"
                class="form-control"
                value={(userData?.pincode) || ""}
            />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>City</label>
                                <input type="text" name="name" class="form-control"    value={userData?.city || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              city: e.target.value,
            }))
          } />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Area</label>
                                <input type="text" name="name" class="form-control"    value={userData?.area || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              area: e.target.value,
            }))
          } />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Native</label>
        {isEditMode ? (
            <Select
                style={{ width: '100%' }}
                placeholder="Select native"
                value={nativee ? nativee : userData.nativee}
                onChange={(value) => setNative(value)}
            >
                {/* Map through your static nativeOptions array */}
                {nativeOptions.map((option) => (
                    <Option key={option} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="name"
                class="form-control"
                value={(userData?.nativee) || ""}
            />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-6">
                <div class="form-group number">
                  <label>Kovil</label>
                  {isEditMode ? (
                    <Select
                      value={kovil ? kovil : userData.kovil}
                      onChange={handleKovilChange}
                      style={{ width: '100%' }}
                    >
                      <Option value="Vairavan Kovil">Vairavan Kovil</Option>
                      <Option value="Ilayathakudi">Ilayathakudi</Option>
                      <Option value="Mathur">Mathur</Option>
                      <Option value="Iraniyur">Iraniyur</Option>
                      <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
                      <Option value="Soorakudi">Soorakudi</Option>
                      <Option value="Iluppaikkudi">Iluppaikkudi</Option>
                      <Option value="Nemam">Nemam</Option>
                      <Option value="Velankudi">Velankudi</Option>
                    </Select>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      value={userData?.kovil || ""}
                    />
                  )}
                </div>
              </div>   

              <div class="col-lg-6 col-md-6">
                <div class="form-group number">
                  <label>Perivu</label>
                  {isEditMode ? (
                    <div class="custom-select-select">
                      <select
                        value={selectedPerivu!=null ? selectedPerivu : userData.perivu}
                        onChange={(e) => handlePerivuChange(e.target.value)}
                      >
                        {perivu.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      value={userData?.perivu || ""}
                    />
                  )}
                </div>
              </div>
              <div class="col-lg-6 col-md-4">
                  <div class="form-group number">
                    <label>Mobile</label>
                    {isEditMode ? (
                      <input
                        type="tel"
                        name="text"
                        class={`form-control ${
                          mobile.length !== 10 ? "custom-invalid" : ""
                        }`}
                        placeholder="Enter mobile number"
                        value={mobile? mobile : userData.mobile}
                        onChange={handleMobileChangeLogin}
                        disabled
                      />
                    ) : (
                      <input
                        type="tel"
                        name="text"
                        class="form-control"
                        value={userData?.mobile || ""}
                        disabled
                      />
                    )}
                    {mobile.length < 10 && mobile.length > 0 && (
                      <div className="error-message" style={{ color: "red" }}>
                        Please enter 10 digits
                      </div>
                    )}
                  </div>
                </div> 
                <div class="col-lg-6 col-md-6">
                  <div class="form-group number">
                    <label>Email</label>
                    {isEditMode ? (
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Enter email"
                        value={email? email: userData.email}
                        onChange={handleEmailChange}
                        disabled
                      />
                    ) : (
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        value={userData?.email || ""}
                        disabled
                      />
                    )}
                    {!isValidEmail && (
                      <p style={{ color: "red" }}>
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                </div> 
                <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Proposer Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.proposername || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              proposername: e.target.value,
            }))
          }  />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Secordor Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.secordorname || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              secordorname: e.target.value,
            }))
          }/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Year of Joining</label>
                                <input type="text" name="name" class="form-control"    value={userData?.yearofjoining || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              yearofjoining: e.target.value,
            }))
          } disabled={!isEditMode} />
                            </div>
                        </div>
                        <h3 class="main-title">Spouse  Details</h3>
                        <div class="row">
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.spousename || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              spousename: e.target.value,
            }))
          } />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Native</label>
        {isEditMode ? (
            <Select
                style={{ width: '100%' }}
                placeholder="Select native"
                value={spousenativee ? spousenativee : userData.spousenativee}
                onChange={(value) => setSpousenativee(value)}
            >
                {/* Map through your static nativeOptions array */}
                {nativeOptionsforSpouse.map((option) => (
                    <Option key={option} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="name"
                class="form-control"
                value={(userData?.spousenativee) || ""}
            />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-6">
                <div class="form-group number">
                  <label>Kovil</label>
                  {isEditMode ? (
                    <Select
                      value={spousekovil ? spousekovil : userData.spousekovil}
                      onChange={handleKovilChangeforspouse}
                      style={{ width: '100%' }}
                    >
                      <Option value="Vairavan Kovil">Vairavan Kovil</Option>
                      <Option value="Ilayathakudi">Ilayathakudi</Option>
                      <Option value="Mathur">Mathur</Option>
                      <Option value="Iraniyur">Iraniyur</Option>
                      <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
                      <Option value="Soorakudi">Soorakudi</Option>
                      <Option value="Iluppaikkudi">Iluppaikkudi</Option>
                      <Option value="Nemam">Nemam</Option>
                      <Option value="Velankudi">Velankudi</Option>
                    </Select>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      value={userData?.spousekovil || ""}
                    />
                  )}
                </div>
              </div> 
              
              <div class="col-lg-6 col-md-6">
                <div class="form-group number">
                  <label>Perivu</label>
                  {isEditMode ? (
                    <div class="custom-select-select">
                      <select
                        value={selectedPerivuforspouse}
                        onChange={(e) => handlePerivuChangeforspouse(e.target.value)}
                      >
                        {spouseperivu.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      value={userData?.spouseperivu || ""}
                    />
                  )}
                </div>
              </div> 
               
                        </div>
                        
                        <h3 class="main-title">Father  Details</h3>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.fathersname || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              fathersname: e.target.value,
            }))
          }  />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Native</label>
        {isEditMode ? (
            <Select
                style={{ width: '100%' }}
                placeholder="Select native"
                value={fathersnativee ? fathersnativee : userData.fathersnativee}
                onChange={(value) => setFathernativee(value)}
            >
                {/* Map through your static nativeOptions array */}
                {nativeOptionsforFather.map((option) => (
                    <Option key={option} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="name"
                class="form-control"
                value={(userData?.fathersnativee) || ""}
            />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-6">
                <div class="form-group number">
                  <label>Kovil</label>
                  {isEditMode ? (
                    <Select 
                    value={fatherskovil ? fatherskovil : userData.fatherskovil}
                      onChange={handleKovilChangeforfather}
                      style={{ width: '100%' }}
                    >
                      <Option value="Vairavan Kovil">Vairavan Kovil</Option>
                      <Option value="Ilayathakudi">Ilayathakudi</Option>
                      <Option value="Mathur">Mathur</Option>
                      <Option value="Iraniyur">Iraniyur</Option>
                      <Option value="Pillaiyarpatti">Pillaiyarpatti</Option>
                      <Option value="Soorakudi">Soorakudi</Option>
                      <Option value="Iluppaikkudi">Iluppaikkudi</Option>
                      <Option value="Nemam">Nemam</Option>
                      <Option value="Velankudi">Velankudi</Option>
                    </Select>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      value={userData?.fatherskovil || ""}
                    />
                  )}
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group number">
                  <label>Perivu</label>
                  {isEditMode ? (
                    <div class="custom-select-select">
                      <select
                      value={selectedPerivuforfather!=null ? selectedPerivuforfather : userData.fathersperivu}
                        onChange={(e) => handlePerivuChangeforfather(e.target.value)}
                      >
                        {fathersperivu.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      value={userData?.fathersperivu || ""}
                    />
                  )}
                </div>
              </div>
              
              <h3 class="main-title">Business  Details</h3>
              <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Company Name</label>
                                <input type="text" name="name" class="form-control"    value={userData?.companyname || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              companyname: e.target.value,
            }))
          } />
                            </div>
                        </div> 
                         <div class="col-lg-6 col-md-6">
                  <div class="form-group number">
                    <label>Category</label>
                    {isEditMode ? (
                      <Select
                        style={{ width: "100%", height: "50px" }}
                        placeholder="Select category"
                        value={companycategory? companycategory : userData.companycategory}
                        onChange={(value) => setCompanycategory(value)}
                      >
                        {data.map((category) => (
                          <Option
                            key={category.companycategoryid}
                            value={category.companycategory}
                          >
                            {category.companycategory}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <input
                        type="text"
                        name="text"
                        class="form-control"
                        value={userData?.companycategory || ""}
                      />
                    )}
                  </div>
                </div>
                <div class="col-lg-6 col-md-4">
                  <div class="form-group number">
                    <label>Mobile</label>
                    {isEditMode ? (
                      <input
                        type="tel"
                        name="text"
                        class={`form-control ${
                          companymobile.length !== 10 ? "custom-invalid" : ""
                        }`}
                        placeholder="Enter mobile number"
                        value={companymobile?companymobile : userData.companymobile }
                        onChange={handleMobileChangeLoginforComapny}
                      />
                    ) : (
                      <input
                        type="tel"
                        name="text"
                        class="form-control"
                        value={userData?.companymobile || ""}
                      />
                    )}
                    {companymobile.length < 10 && companymobile.length > 0 && (
                      <div className="error-message" style={{ color: "red" }}>
                        Please enter 10 digits
                      </div>
                    )}
                  </div>
                </div> 
                <div class="col-lg-6 col-md-6">
                  <div class="form-group number">
                    <label>Company Email</label>
                    {isEditMode ? (
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Enter email"
                        value={companyemail? companyemail : userData.companyemail}
                        onChange={handleEmailChangeforcompany}
                      />
                    ) : (
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        value={userData?.companyemail || ""}
                      />
                    )}
                    {!isValidEmailforcomapny && (
                      <p style={{ color: "red" }}>
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Website Address</label>
                                <input type="text" name="name" class="form-control"    value={userData?.companywebsite || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              companywebsite: e.target.value,
            }))
          }  />
                            </div>
                        </div> 
                        
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Address 1</label>
                                <input type="text" name="name" class="form-control"    value={userData?.companyaddress1 || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              companyaddress1: e.target.value,
            }))
          }  />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Country</label>
        {isEditMode ? (
            <div style={{ position: 'relative' }}>
                <Select
                    placeholder="Select country"
                    value={selectedCountryforcompany?selectedCountryforcompany:(userData?.companycountry) || ""}
                    style={{ width: '100%', height: "55px" }}
                    onChange={(value) => handleCountryClickforCompany(value)}
                >
                    {companycountryList.map((country) => (
                        <Option key={country.pincodeid} value={country.country}>
                            {country.country}
                        </Option>
                    ))}
                </Select>
            </div>
        ) : (
            <input
                type="text"
                name="text"
                class="form-control"
                value={(userData?.companycountry) || ""}
            />
        )}
    </div>
    </div>
    <div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>State</label>
        {isEditMode ? (
            <Select
                className="form-control"
                name="state"
                required
                value={selectedStateforcompany?selectedStateforcompany:(userData?.companystate) || ""}
                onChange={(value) => handleStateClickforCompany(value)}
                style={{ width: '100%' }}
                mode="default" 
            >
                {companystateList.map((stateItem) => (
                    <Option key={stateItem.pincodeid} value={stateItem.state}>
                        {stateItem.state}
                    </Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="text"
                class="form-control"
                value={userData?.companystate || ""}
            />
        )}
</div>
</div>
<div class="col-lg-6 col-md-6">
    <div class="form-group number">
        <label>Pincode</label>
        {isEditMode ? (
            <Select
                style={{ width: "100%" }}
                showSearch
                onPopupScroll={handleDropdownScroll}
                placeholder="Select pincode"
                value={companypincode?companypincode:(userData?.companypincode) || ""}
                onChange={(value) => setCompanypincode(value)}
                optionLabelProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {companypincodeList.map((pincode, index) => (
                    <Select.Option key={pincode.pincodeid || index} value={pincode.pincode}>
                        {pincode.pincode}
                    </Select.Option>
                ))}
            </Select>
        ) : (
            <input
                type="text"
                name="text"
                class="form-control"
                value={(userData?.companypincode) || ""}
            />
        )}
    </div>
</div>
<div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>City</label>
                                <input type="text" name="name" class="form-control"    value={userData?.companycity || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              companycity: e.target.value,
            }))
          }  />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                            <div class="form-group name">
                                <label>Area</label>
                                <input type="text" name="name" class="form-control"    value={userData?.companyarea || ""}
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              companyarea: e.target.value,
            }))
          }  />
                            </div>
                        </div>
                    </div>
                    
                </form>
    <button
        type="submit"
        className="btn-md button-theme"
        style={{ width: "10%" }}
        onClick={isEditMode ? handleSaveButtonClick : handleEditButtonClick}
      >
        {isEditMode ? "Save" : "Edit"}
      </button>
            </div>
        </div>
       
           
            
       
    </div>
</div>
</div>


</div>

<Footer/>
</div>

    )
}
export default ProfileImage;