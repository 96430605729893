import React from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGooglePlus, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Image from"../img/avatar/founding_member.jpg";
import Footer from "../footer";
import Header from "../header";
function Acemember() {
    return(
        <div>
            <Header/>
        <div class="our-team content-area">
    <div class="container">
        {/* <!-- Main title --> */}
        <div class="main-title">
            <h1>ACE Members</h1>
            <p> </p>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="team-1">
                    <div class="team-photo">
                        <a href="#">
                            <img src={Image} alt="agent" class="img-fluid"/>
                        </a>
                        <ul class="social-list clearfix">
                        <li><a href="#" className="facebook-bg"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a href="#" className="twitter-bg"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a href="#" className="google-bg"><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                        <li><a href="#" className="linkedin-bg"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        </ul>
                    </div>
                    <div class="team-details">
                        <h5><a href="#">Founding Member 1</a></h5>
                        <h6>Bisiness name</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="team-1">
                    <div class="team-photo">
                        <a href="#">
                            <img src={Image} alt="agent" class="img-fluid"/>
                        </a>
                        <ul class="social-list clearfix">
                        <li><a href="#" className="facebook-bg"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a href="#" className="twitter-bg"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a href="#" className="google-bg"><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                        <li><a href="#" className="linkedin-bg"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        </ul>
                    </div>
                    <div class="team-details">
                        <h5><a href="#">Founding Member 2</a></h5>
                        <h6>Bisiness name</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="team-1">
                    <div class="team-photo">
                        <a href="#">
                            <img src={Image} alt="agent" class="img-fluid"/>
                        </a>
                        <ul class="social-list clearfix">
                        <li><a href="#" className="facebook-bg"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a href="#" className="twitter-bg"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a href="#" className="google-bg"><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                        <li><a href="#" className="linkedin-bg"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        </ul>
                    </div>
                    <div class="team-details">
                        <h5><a href="#">Founding Member 3</a></h5>
                        <h6>Bisiness name</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div class="team-1">
                    <div class="team-photo">
                        <a href="#">
                            <img src={Image} alt="agent" class="img-fluid"/>
                        </a>
                        <ul class="social-list clearfix">
                        <li><a href="#" className="facebook-bg"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a href="#" className="twitter-bg"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        <li><a href="#" className="google-bg"><FontAwesomeIcon icon={faGooglePlus} /></a></li>
                        <li><a href="#" className="linkedin-bg"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        </ul>
                    </div>
                    <div class="team-details">
                        <h5><a href="#">Founding Member 4</a></h5>
                        <h6>Bisiness name</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<Footer/>
</div>
)
}
export default Acemember;