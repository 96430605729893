import React, { useState, useEffect } from "react";
import "../App.css";
import "../css/style.css";
import "../css/bootstrap.min.css";
import "../css/default.css";
import "../css/slick.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faGooglePlus,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Image from "../img/current_memenber/Mr.Thirupathi - Mentor.png";
import Image2 from "../img/current_memenber/Muthu Kannan - President.jpeg";
import Image3 from "../img/current_memenber/Uma Meiyappan - Secretary.jpeg";
import Image4 from "../img/current_memenber/Lakshmanan VR - Treasurer.jpg";
import Image5 from "../img/current_memenber/N.Subramanian  - Ec Member.jpg";
import Image6 from "../img/current_memenber/VT.Chandhrasekharan - EC Member.jpg";
import Image7 from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import noImage from "../img/noimage.jpg";

function Founding() {
  const [data, setData] = useState([]);
  const [ecdata, setDataec] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCompanyCategories = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/companycategory/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setCompanyCategories(response.data);
    } catch (error) {
      console.error("Error fetching company categories:", error);
    }
  };
  useEffect(() => {
    fetchCompanyEcMember();
    fetchCompanyCategories();
    return () => {
      localStorage.removeItem("selectedCategory");
    };
  }, []);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          "https://api.nagaratharcoc.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    const filteredResults = data.filter(
      (item) =>
        (selectedCategory ? item.companycategory === selectedCategory : true) &&
        (item.idno.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.companyname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.companycategory
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.address1.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredData(filteredResults);
  }, [searchQuery, data, selectedCategory]);

  const handleRowClick = async (item) => {
    try {
      // Assuming item.id is the memberid you want to pass
      const clientid = item.clientid;
      localStorage.setItem("clickedClientId", clientid);
      // Navigate to the UserProfile page or handle the response accordingly
      navigate(`/UserProfile`);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const fetchCompanyEcMember = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/ecmember/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setDataec(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching company categories:", error);
    }
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedCategory");
    };
  }, []);
  const getMonthName = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[month - 1];
  };
  return (
    <div>

      <Header />

      <div class="our-team content-area">
        <div class="container">
          <div class="main-title">
            <h1>EC Member History</h1>
            <p>
              We established the chamber on 15th August 2016 with a vision of
              expanding our views to a large group of individuals.
            </p>
            <p>
              The former president, Mr.EMC Palaniappan planted the seeds for the
              growth. The growth is evolving with various presidents at present.
            </p>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div class="row  d-flex justify-content-center">
              {Object.entries(
                ecdata.reduce((acc, item) => {
                  const period = `${getMonthName(item.startmonth)} ${
                    item.startyear
                  } - ${getMonthName(item.endmonth)} ${item.endyear}`;
                  if (!acc[period]) {
                    acc[period] = [];
                  }
                  acc[period].push(item);
                  return acc;
                }, {})
              ).map(([period, group], index) => (
                <div key={index} class="col-12 text-center">
                  <h4 className="m-4 fw-bold">{period}</h4>
                      
                      {/* Render Presidents */}
                      <div className="row d-flex justify-content-center">
                          {group.filter(item => item.role === 'President').map((item, index) => (
                              <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  {/* Render president details */}
                                  <div className="team-1">
                                      <div className="team-photo">
                                          <a href="#">
                                              <img src={item.clientdetail.profilepic || noImage} alt="agent" />
                                          </a>
                                      </div>
                                      <div className="team-details text-center">
                                          <h5><a href="#">{item.clientdetail.firstname}.{item.clientdetail.lastname}</a></h5>
                                          <h6>{item.clientdetail.city}</h6>
                                          <p>{item.clientdetail.companyname}<br />
                                              {item.clientdetail.companycategory}</p>
                                          <p>{getMonthName(item.startmonth)} {item.startyear} - {getMonthName(item.endmonth)} {item.endyear}</p>
                                          <p>{item.role}</p>
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </div>

                      {/* Render Secretaries and Treasurers */}
                      <div className="row d-flex justify-content-center">
                          {group.filter(item => item.role === 'Secretary' || item.role === 'Treasuer').map((item, index) => (
                              <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  {/* Render secretary or treasurer details */}
                                  <div className="team-1">
                                      <div className="team-photo">
                                          <a href="#">
                                              <img src={item.clientdetail.profilepic || noImage} alt="agent" />
                                          </a>
                                      </div>
                                      <div className="team-details text-center">
                                          <h5><a href="#">{item.clientdetail.firstname}.{item.clientdetail.lastname}</a></h5>
                                          <h6>{item.clientdetail.city}</h6>
                                          <p>{item.clientdetail.companyname}<br />
                                              {item.clientdetail.companycategory}</p>
                                          <p>{getMonthName(item.startmonth)} {item.startyear} - {getMonthName(item.endmonth)} {item.endyear}</p>
                                          <p>{item.role}</p>
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </div>

                      {/* Render EC Members */}
                      <div className="row d-flex justify-content-center">
                          {group.filter(item => item.role === 'ECMember').map((item, index) => (
                              <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  {/* Render EC member details */}
                                  <div className="team-1">
                                      <div className="team-photo">
                                          <a href="#">
                                              <img src={item.clientdetail.profilepic || noImage} alt="agent" />
                                          </a>
                                      </div>
                                      <div className="team-details text-center">
                                          <h5><a href="#">{item.clientdetail.firstname}.{item.clientdetail.lastname}</a></h5>
                                          <h6>{item.clientdetail.city}</h6>
                                          <p>{item.clientdetail.companyname}<br />
                                              {item.clientdetail.companycategory}</p>
                                          <p>{getMonthName(item.startmonth)} {item.startyear} - {getMonthName(item.endmonth)} {item.endyear}</p>
                                          <p>{item.role}</p>
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </div>
                      </div>
                    )
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Founding;
