import React, { useState, useEffect  } from "react";
import '../App.css';
import "../css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/default.css";
import "../css/slick.css";
import Image from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import noImage from "../img/noimage.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function UserProfileShow() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const getClientDetails = async () => {
        try {
            const response = await axios.post(
                "https://api.nagaratharcoc.com/api/v1/login/getclientdetails",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 401 || response.status === 404) {
                // Redirect to the login page for 401 or 404 errors
                navigate('/login');
                return; // Exit early to prevent further logic execution
            }
    
            // Handle the response data as needed
            const idFromResponse = response.data.id;
            if(idFromResponse!=null){
              const fetchUserDetails = async () => {
                  try {
                    const response = await axios.post(
                      "https://api.nagaratharcoc.com/api/v1/client/getbyid",
                      { clientid: parseInt(idFromResponse )},
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem('token')}`,
                          "Content-Type": "application/json",
                        },
                      }
                    );
          
                    // Set the user data in state
                    setUserData(response.data);
                  } catch (error) {
                    console.error("Error fetching user details:", error);
                  }
                };
          
                fetchUserDetails();
            }
            // Store the id in localStorage
            localStorage.setItem('clientid', idFromResponse);
    
            // Set the id in the state
        } catch (error) {
            console.error("Error fetching client details:", error);
        }
    };
    
  
      useEffect(() => {
          // Fetch client details when the component mounts
          getClientDetails();
      }, []);
return(
        <div>        
        <Header/>
    <div class="our-team content-area">
<div class="container">
    
    {/* <div class="main-title">
        <h1>YES Members</h1>
        <p></p>
    </div> */}
    
    <div class="row own">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="team-1">
                
        <Link to="/ProfileImage">
        <button
        type="submit"
        className="btn-md button-theme mb-4"
        style={{ width: "10%" }}>Edit</button>
        </Link>
                <div class="team-photo" style={{ backgroundColor: "#2b3d58" }}>
                    <a href="#">
                        <img  src={userData?.profilepic || noImage} alt="agent" class="img-fluid" style={{ margin: "15px" }}/>
                    </a>
    {userData && (
                    <div className="#" style={{ color: "#fff" }}>
                                            <h2>{userData.firstname}.{userData.lastname}</h2>
                    <h5>{userData.companyname}</h5>
                    <h5>{userData.companycategory}</h5>
                    </div>

                    
 )}
                    
                  
                </div>
            </div>
        </div>
       
      
    </div>
    
    {userData && (
    <div className="row">
    <div class="col-xl-6 col-lg-3 col-md-6 col-sm-6 align">
        <div className="#" style={{ color: "#000" }}>

<h2>Business Address</h2>
<h5>{userData.companyaddress1}</h5>
<h5>{userData.companycity && `${userData.companycity}, `} {userData.companystate && `${userData.companystate}, `} {userData.companycountry  && `${userData.companycountry}, `}{userData.companypincode  && `${userData.companypincode}`}</h5>
                    <h5>{userData.companyarea}</h5> 
                    <h5>Mobile : {userData.companymobile}</h5>   <h5>Email : {userData.companyemail}</h5>
                    <h5>{userData.companywebsite}</h5>
                    <h5>{userData.companydescription}</h5>
</div>

        </div>
       

        <div class="col-xl-6 col-lg-3 col-md-6 col-sm-6 align">
        <div className="#" style={{ color: "#000" }}>

<h2>Contact</h2>
<h5>Email: {userData.email}</h5>
<h5>Mobile: {userData.mobile}</h5>
<h5>{userData.companyaddress1}</h5>
<h5>{userData.city && `${userData.city}, `} {userData.state && `${userData.state}, `} {userData.country  && `${userData.country}, `}{userData.companypincode  && `${userData.pincode}`}</h5>
<h5>{userData.nativee && `${userData.nativee}, `} {userData.kovil && `${userData.kovil}, `} {userData.perivu  && `${userData.perivu}`}</h5>
<h5>Year of Joining : {userData.yearofjoining}</h5>
<h5>Date of Birth : {userData.dateofbirth}</h5>
</div>

        </div>
        </div>
    
    )}
</div>


</div>

<Footer/>
</div>

    )
}
export default UserProfileShow;