import React, { useState, useEffect } from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import Header from"../../src/header/index"
import Footer from "../footer";
import axios from "axios";

function Byelaw() {
    const [termsConditions, setTermsconditions] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchCommonDetails = async () => {
    try {
      const payload = {
        commondetailsfield: "byelaw",
      };
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/commondetails/getcommondetails",
        payload
      );

      if (response.status === 200) {
        // Successfully fetched common details
        const commonDetails = response.data;
        setTermsconditions(commonDetails.commondetails);
        setLoading(false); // Set loading to false once data is received
        // Do something with commonDetails, such as updating your component's state
        console.log("Common Details:", commonDetails);
      } else {
        console.error("Failed to fetch common details");
      }
    } catch (error) {
      console.error("Error fetching common details:", error);
    }
  };

  // useEffect to fetch common details when the component mounts
  useEffect(() => {
    fetchCommonDetails();
  }, []);
    return(
        <div>
            <Header/>
<div class="about_us  content-area-5">
    
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
			 
			<div class="main-title">


					<h3>Bye law </h3>
                    
                       {/* <p> Nagarathar chamber of Commerce, shortly known as NCC is formed by a group of young entrepreneurs who are likeminded for a social cause, to bring up the young nagarathars who are finding their future in lucrative jobs and overseas opportunities back to commerce which is the lifeline of Nagarathars.</p> */}
				</div>  
                
               
               {/* <div class="about-slider-box simple-slider text-center">
                    <img class="w-50" src="img/cretificate/NCC-reg.jpg" alt=""/>
                </div>  */}
            </div>
            

        <div class="row d-flex content-justify-center">
            <div class="col-xl-12 col-lg-12 col-md-12 ">
            <div
                      dangerouslySetInnerHTML={{
                        __html: termsConditions,
                      }}
                    />
                  </div>
            </div>
        </div>

</div>
</div>
<Footer/>
</div>
    )
}
export default Byelaw;